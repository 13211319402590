import React from 'react'
import { GlobalMenu } from 'packages/eid-ui'
import useConfiguration from 'useConfiguration'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { useAllApplications } from 'hooks'

const appNameMaps = {
    EIDEmpowerIDWeb: 'EmpowerID',
    EIDAzureAnalyticsMS: 'AzureAnalytics',
    EIDITShopMS: 'ITShop',
    EIDMyIdentity: 'MyIdentity',
    EIDMyTasks: 'MyTasks',
}

const MobileMenu = ({ history }) => {
    const {
        location: { pathname },
    } = history

    const { t } = useTranslation()

    const { resourceTypes } = useConfiguration()
    const areaName = pathname.split('/')[1].toLowerCase()

    const isActive = (name) => {
        if (areaName !== 'manageAccess'.toLowerCase()) {
            return areaName === name.toLowerCase()
        } else {
            return pathname.split('/')[2].toLowerCase() === name.toLowerCase()
        }
    }

    const routes =
        areaName !== 'manageAccess'.toLowerCase()
            ? resourceTypes.map((r) => ({
                  name: t(r.title),
                  route: r.route,
                  icon: r.icon,
                  active: isActive(r.name),
              }))
            : resourceTypes.map((r) => ({
                  name: t(r.title),
                  route: `/manageAccess${r.route}`,
                  icon: r.icon,
                  active: isActive(r.name),
              }))

    const { data, isLoading } = useAllApplications()

    const eidApp = data?.find((a) => a.applicationName === 'EIDEmpowerIDWeb')

    return (
        <GlobalMenu.Mobile
            currentSection={
                areaName !== 'manageAccess'.toLowerCase()
                    ? t('Common_RequestAccess')
                    : t('Common_ManageAccess')
            }
            loading={isLoading}
            routes={routes}
            settings={{ signOut: { label: t('Common_SignOut') } }}
            eid={
                eidApp
                    ? {
                          name: appNameMaps[eidApp.applicationName],
                          displayName: eidApp.applicationDisplayName,
                          url: eidApp.applicationAbsoluteUrl,
                      }
                    : undefined
            }
            apps={data
                ?.filter((f) => f.applicationName !== 'EIDEmpowerIDWeb')
                .map((a) => ({
                    name: appNameMaps[a.applicationName],
                    displayName: a.applicationDisplayName,
                    url: a.applicationAbsoluteUrl,
                }))}
            current={t('Common_ITShop')}
            colorCodes={{ primary: '#307fc1', secondary: '#000000' }}
        />
    )
}

export default withRouter(MobileMenu)
