import { useTranslation } from 'react-i18next'
import LockedPasswordBox from './LockedPasswordBox'

const CredentialsLockedAttribute = ({ attribute, data }) => {
    const { t } = useTranslation()
    return (
        <LockedPasswordBox
            targetUrl={`${attribute.component.targetUrl}/${data.resource.id}`}
            isClickable={!data.resource.canCheckOut}
            validationMessage={t(attribute.component.validationMessage)}
            targetProperty={attribute.component.targetProperty}
        />
    )
}

export default CredentialsLockedAttribute
