import {
    preApprovedOnlyFilterColors,
    directAssignedOnlyFilterColors,
    timeConstrainedOnlyFilterColors,
} from './colors'

const filters = [
    {
        forRequestAccess: true,
        forManageAccess: true,
        name: 'ShoppingFor',
        requireAccess: {
            control: 'ITShop-ShopForTargetPerson-Control',
        },
        order: 1,
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyDirectAssigned',
        title: 'Common_ShowDirectAssignedOnly',
        requireAccess: {
            control: 'ITShop-PreApprovedComputers-Control',
        },
        order: 2,
        colorCode: directAssignedOnlyFilterColors,
        label: 'Common_ShowDirectAssignedOnly',
        type: 'checkbox',
        contextProp: 'showDirectAssignedOnly',
        trueValueIndicator: 'Common_ShowDirectAssignedOnly',
        queryParam: {
            name: 'showDirectAssignedOnly',
        },
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyTimeConstrained',
        title: 'ManageAccess_ShowTimeConstrained',
        requireAccess: {
            control: 'ITShop-TimeConstrainedComputers-Control',
        },
        order: 2,
        colorCode: timeConstrainedOnlyFilterColors,
        label: 'ManageAccess_ShowTimeConstrained',
        type: 'checkbox',
        contextProp: 'showTimeConstrained',
        trueValueIndicator: 'ManageAccess_ShowTimeConstrained',
        queryParam: {
            name: 'showTimeConstrained',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'ShowOnlyPreApproved',
        title: 'Common_ShowOnlyPreApproved',
        requireAccess: {
            control: 'ITShop-PreApprovedComputers-Control',
        },
        order: 2,
        colorCode: preApprovedOnlyFilterColors,
        label: 'Common_ShowOnlyPreApproved',
        type: 'checkbox',
        contextProp: 'showPreApproved',
        trueValueIndicator: 'Common_ShowOnlyPreApproved',
        queryParam: {
            name: 'showPreApproved',
        },
    },
    {
        forRequestAccess: true,
        name: 'TargetSystem',
        title: 'Common_TargetSystem',
        requireAccess: {
            control: 'ITShop-TargetSystem-Control',
        },
        order: 3,
        colorCode: {
            primary: '#307fc1',
            secondary: 'rgba(48, 127, 193, 0.09)',
        },
        contextProp: 'accountStore',
        type: 'autocomplete',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        queryParam: {
            name: 'AccountStoreGUID',
            type: 'guid',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'Environment',
        title: 'Computers_Environment',
        requireAccess: {
            control: 'ITShop-ComputersEnvironment-Control',
        },
        order: 4,
        colorCode: {
            primary: '#dfba00',
            secondary: '#fdfae5',
        },
        type: 'autocomplete',
        label: 'Computers_Environment',
        placeholder: 'Computers_Environment',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        url: '/api/Computers/environmentTypes',
        queryParams: {
            take: 25,
        },
        debounceInterval: 100,
        dedupingInterval: 1800000,
        contextProp: 'environment',
        queryParam: {
            name: 'environmentTypeId',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'OperatingSystem',
        title: 'Common_OperatingSystem',
        requireAccess: {
            control: 'ITShop-ComputersOperatingSystem-Control',
        },
        order: 5,
        colorCode: {
            primary: '#01745f',
            secondary: '#e6f1ef',
        },
        type: 'text',
        label: 'Common_OperatingSystem',
        placeholder: 'Common_OperatingSystem',
        contextProp: 'operatingSystem',
        queryParam: {
            name: 'operatingSystem',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'InstanceType',
        title: 'Computers_InstanceType',
        requireAccess: {
            control: 'ITShop-ComputersInstanceType-Control',
        },
        order: 6,
        colorCode: {
            primary: '#ff5e65',
            secondary: '#fee8f2',
        },
        type: 'text',
        label: 'Computers_InstanceType',
        placeholder: 'Computers_InstanceType',
        contextProp: 'instanceType',
        queryParam: {
            name: 'instanceType',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'AdvancedSearch',
        title: 'Common_AdvancedSearch',
        requireAccess: {
            control: 'ITShop-ComputersAdvancedSearch-Control',
        },
        order: 7,
        icon: {
            name: 'AdvancedSearch',
            color: '#959598',
        },
        type: 'advancedSearch',
        forms: {
            title: 'Common_Forms',
            colorCode: {
                primary: '#2920ad',
                secondary: '#ebeaf7',
            },
            filters: [
                {
                    name: 'FriendlyName',
                    type: 'text',
                    placeholder: 'Common_FriendlyName',
                    contextProp: 'advancedSearch.forms.friendlyName',
                    queryParam: {
                        name: 'friendlyName',
                        type: 'string',
                    },
                },
                {
                    name: 'dnsHostname',
                    type: 'text',
                    placeholder: 'Common_DnsHostName',
                    contextProp: 'advancedSearch.forms.dnsHostName',
                    queryParam: {
                        name: 'dnsHostName',
                        type: 'string',
                    },
                },
                {
                    name: 'computerTypeName',
                    type: 'text',
                    placeholder: 'Computers_ComputerType',
                    contextProp: 'advancedSearch.forms.computerTypeName',
                    queryParam: {
                        name: 'computerTypeName',
                        type: 'string',
                    },
                },
            ],
        },
        tags: {
            title: 'Common_Tags',
            colorCode: {
                primary: '#f81983',
                secondary: '#fee8f2',
            },
            contextProp: 'advancedSearch.tags',
        },
    },
]

const attributes = [
    //Add columnName property as sorting property for Request Access and manageAccessColumnName property for Manage Access if sorting can't be done on name property

    {
        name: 'friendlyName',
        label: 'Common_FriendlyName',
        isHeader: true,
        sortable: true,
        style: { fontWeight: 'bold', color: '#282828' },
        moreInfoIcon: { hideInManageAccess: true },
    },
    {
        name: 'dnsHostName',
        label: 'Common_DnsHostName',
        sortable: true,
    },
    {
        name: 'environmentTypeFriendlyName',
        label: 'Computers_Environment',
        sortable: true,
    },
    {
        name: 'operatingSystem',
        label: 'Common_OperatingSystem',
        sortable: true,
    },
    {
        name: 'assignee',
        label: 'Common_AssignedVia',
        manageAccessOnlyAttribute: true,
    },
]
const computerConfig = {
    name: 'Computers',
    resourceTypeName: 'Computer',
    title: 'Common_Computers',
    route: '/computers',
    requireAccess: {
        page: 'ITShop-Computers-Page',
    },
    order: 10,
    icon: {
        small: 'ComputersSmall',
        large: 'ComputersLarge',
    },
    dataSource: {
        type: 'MS_DS',
        url: '/api/computers',
    },
    showTimeConstrainedFilter: true,
    showDirectAssignedOnlyFilter: true,
    workflows: [],
    filters: filters,
    attributes: attributes,
}

export default computerConfig
