import { FC, Fragment, useState } from 'react'
import { Box, styled, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ItemDetails, TimeDurationSelector } from 'components'
import { Loader, Tooltip, WarningModal } from 'packages/eid-ui'
import { useAccessRequestPolicy, useAddItemToCart, useCart } from 'hooks'
import { Icon } from 'packages/eid-icons'
import { useTimeConstrainedControl } from 'components/TimeConstrainedControl'
import { IResource, IResourceType } from 'core'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export type RenewRevokeProps = {
    resourceType: IResourceType
    resource: IResource
    secondary: any
    assignment: any
    preRenew: any
    postRenew: any
    preRevoke: any
    postRevoke: any
}

export const RenewRevoke: FC<RenewRevokeProps> = (props) => {
    const { t } = useTranslation()

    const { data: cart } = useCart()

    const isBusinessRole = props.resourceType.name === 'BusinessRoles'

    const accessRequestPolicy = useAccessRequestPolicy(
        props.resource.requestPolicyId,
        isBusinessRole ? props.resource.id : '',
        isBusinessRole ? props.secondary.id : '',
    )

    const [addItemToCart, { isLoading: isAdding }] = useAddItemToCart()

    const [showConfirm, setConfirm] = useState(false)

    const timeConstrainedActive =
        props.assignment.assignmentDetails.timeConstraintActive

    const {
        timeConstrained,
        selectedStartDate,
        selectedEndDate,
        hasInvalidDates,
        violatesPolicy,
        startDateControl,
        endDateControl,
    } = useTimeConstrainedControl(
        accessRequestPolicy,
        timeConstrainedActive,
        true,
    )

    if (!cart || !accessRequestPolicy) return <Loader />

    const itemAlreadyInCart = props.resourceType.alreadyInCart(
        cart.cartItems,
        props.resource,
        props.secondary,
    )

    const handleRevoke = () => {
        const itemToRevoke = props.preRevoke()
        if (itemToRevoke === undefined) return

        addItemToCart(itemToRevoke).then(() => {
            setConfirm(false)
            props.postRevoke()
        })
    }

    const handleRenewal = () => {
        const timeConstraintDetails = {
            timeConstrained: true,
            startDateUtc: selectedStartDate,
            endDateUtc: selectedEndDate,
        }

        const itemToRenew = props.preRenew(timeConstraintDetails)

        if (itemToRenew === undefined) return

        addItemToCart(itemToRenew).then(() => props.postRenew())
    }

    const getRenewTooltipTitle = () =>
        itemAlreadyInCart
            ? t('Common_ItemAlreadyInCart')
            : hasInvalidDates
                ? t('Common_InvalidDates')
                : ''

    const getRevokeTooltipTitle = () =>
        itemAlreadyInCart ? t('Common_ItemAlreadyInCart') : ''

    const showRenew = false

    const showRequestAgain = timeConstrained

    const showRevoke = props.assignment.assignmentDetails.isRevokable

    let validationMessage = ''
    if (violatesPolicy) {
        validationMessage = t('Common_PolicyAllowsMaxDurationInMinError', {
            count: accessRequestPolicy.maximumValueInMinutes,
        })
    }

    return (
        <Fragment>
            {(showRenew || showRevoke || showRequestAgain) && (
                <PaddedDiv
                    style={{
                        minHeight: '104px',
                        paddingTop: '32px',
                        paddingBottom: '20px',
                        alignItems: 'center',
                    }}
                >
                    {showRequestAgain && (
                        <TimeDurationSelector
                            startDateTimeSelector={
                                <Box display="flex" width="220px">
                                    {startDateControl}
                                </Box>
                            }
                            endDateTimeSelector={
                                <Box display="flex" width="220px">
                                    {endDateControl}
                                </Box>
                            }
                            validationMessage={validationMessage}
                        />
                    )}

                    <Box
                        display="flex"
                        width="100%"
                        marginTop={(showRequestAgain || showRenew) ? '56px' : '0px'}
                        position="relative"
                        minHeight="50px"
                    >
                        {showRevoke && (
                            <Tooltip title={getRevokeTooltipTitle()}>
                                <Box>
                                    <ItemDetails.ActionButton
                                        fontColor="#d0021b"
                                        border="solid 1px #d0021b"
                                        loaderColor="#d0021b"
                                        bgColor="#ffffff"
                                        width="120px"
                                        disabled={
                                            getRevokeTooltipTitle() !== ''
                                        }
                                        onClick={() => setConfirm(true)}
                                    >
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            paddingRight="10px"
                                        >
                                            <Icon
                                                name="Close"
                                                color="#d0021b"
                                                height="16px"
                                                width="16px"
                                            />
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                            <Typography
                                                style={{ color: '#d0021b' }}
                                            >
                                                {t('Common_Revoke')}
                                            </Typography>
                                        </Box>
                                    </ItemDetails.ActionButton>
                                    <WarningModal
                                        title={t('Common_Confirm')}
                                        description={t(
                                            'Common_ConfirmationBeforeRevocation',
                                        )}
                                        yesLabel={t('Common_Yes')}
                                        noLabel={t('Common_No')}
                                        open={showConfirm}
                                        cancel={() => setConfirm(false)}
                                        proceeding={isAdding}
                                        proceed={handleRevoke}
                                    />
                                </Box>
                            </Tooltip>
                        )}

                        {showRequestAgain && (
                            <Tooltip title={getRenewTooltipTitle()}>
                                <Box position="absolute" right={0} bottom={0}>
                                    <ItemDetails.ActionButton
                                        disabled={getRenewTooltipTitle() !== ''}
                                        fontColor="#d0021b"
                                        loaderColor="#d0021b"
                                        bgColor="#4142B7"
                                        width="170px"
                                        loading={isAdding}
                                        onClick={handleRenewal}
                                    >
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            paddingRight="10px"
                                        >
                                            <Icon
                                                name="Renew"
                                                color="#ffffff"
                                            />
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                            <Typography
                                                style={{ color: '#ffffff' }}
                                            >
                                                {t('Common_RequestAgain')}
                                            </Typography>
                                        </Box>
                                    </ItemDetails.ActionButton>
                                </Box>
                            </Tooltip>
                        )}

                    </Box>
                </PaddedDiv>
            )}
        </Fragment>
    )
}
