import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box } from '@material-ui/core'

const Attribute = ({
                       maxWidth,
                       label,
                       value,
                       fallbackValue,
                       orientation,
                       labelStyles,
                       valueStyles,
                   }) => {
    const display = orientation === 'vertical' ? 'block' : 'inline'
    const labelText = orientation === 'horizontal' ? `${label}: ` : label
    const valueText = value ? (
        <>{value}</>
    ) : (
        <span style={{ color: 'rgb(180, 180, 180)' }}>{fallbackValue}</span>
    )

    return (
        <Box style={{ maxWidth }}>
            <Typography
                variant="subtitle1"
                display={display}
                style={{ color: '#b4b4b4', ...labelStyles }}
            >
                {labelText}
            </Typography>
            <Typography
                display={display}
                style={{
                    color: '#282828',
                    wordWrap: 'break-word',
                    fontSize: '16px',
                    overflow: 'auto',
                    ...valueStyles,
                }}
            >
                {valueText}
            </Typography>
        </Box>
    )
}

Attribute.propTypes = {
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
}

Attribute.defaultProps = {
    maxWidth: 'fit-content',
    label: '',
    orientation: 'horizontal',
    fallbackValue: '-',
    labelStyles: {},
    valueStyles: {},
}

export default Attribute
