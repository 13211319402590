import { withRouter } from 'react-router'
import StickyContainer from 'components/StickyContainer'
import { Box, Button, styled, Typography } from '@material-ui/core'
import { CardList } from 'packages/eid-controls'
import AccessItemDrawer from '../ManageAccess/AccessItemDrawer'
import { ArrowIcon, Icon } from 'packages/eid-icons'
import {
    Divider,
    LinkTile,
    ToggleSwitch,
    ExpansionPanel,
} from 'packages/eid-ui'
import AccessItem from '../ManageAccess/AccessItem'
import { useGetControlsAccess, useQuery, useUrlGenerator } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { EidFilter, ShoppingFor } from 'components'
import { useResourceTypeContext } from 'resourceTypeContext'
import AccountStoreFilter from '../ManageAccess/AccountStoreFilter'
import {
    getResourceItemFromList,
    getUniqueId,
} from '../ManageAccess/resourceTypeHelpers'
import { useAllApplications } from 'hooks'

const flexCenter = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const maxWidth = '420px'

const ItemsContainer = styled('div')(({ top }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: top ? top : '16px',
    maxWidth: maxWidth,
    margin: 'auto',
    paddingLeft: '6px',
    paddingRight: '6px',
}))

const StyledButton = styled(({ children, label, subLabel, ...other }) => (
    <Button {...other}>
        {children ? (
            children
        ) : (
            <>
                <Typography
                    style={{
                        color: '#363636',
                        fontSize: '14px',
                        lineHeight: '16px',
                    }}
                >
                    {label}
                </Typography>
                <Typography
                    style={{
                        color: '#8b909a',
                        fontSize: '11px',
                        lineHeight: '13px',
                    }}
                >
                    {subLabel}
                </Typography>
            </>
        )}
    </Button>
))(({ border, styles }) => ({
    width: '100%',
    maxWidth: '260px',
    backgroundColor: '#ffffff',
    border: border ? border : '1px solid #d2d2d9',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: '#ffffff',
        border: border ? border : '1px solid #d2d2d9',
        boxShadow: 'none',
    },
    ...styles,
}))

const SwipeableContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'scroll',
    marginBottom: '10px',
    '&::-webkit-scrollbar': {
        width: '0px',
        backgroundColor: 'transparent',
    },
})
const MobileVersion = ({
    id,
    list,
    loading,
    totalCount,
    loadingMore,
    resourceTypes,
    currentResourceType,
    resourceTypeAttributes,
    infiniteScrollObervableDiv,
    history,
}) => {
    const { t } = useTranslation()
    const [{ manageAccess }, dispatch] = useResourceTypeContext()

    const { getMyTasksUrl } = useUrlGenerator(useAllApplications)

    const myTasksUrl = getMyTasksUrl()

    const controls = useGetControlsAccess()
    const hasAccessToTargetPersonSelection =
        controls.findIndex(
            (c) => c.name === 'ITShop-ShopForTargetPerson-Control',
        ) >= 0

    const hasAccessToViewPendingAccess =
        controls.findIndex(
            (c) => c.name === 'ITShop-ViewPendingAccess-Control',
        ) >= 0

    const hasAccessToAccountStoreSelection =
        currentResourceType.name === 'ApplicationRoles' &&
        controls.findIndex((c) => c.name === 'ITShop-TargetSystem-Control') >= 0

    const query = useQuery()
    const showFilters = query.get('filters') === 'visible'
    const cartOpen = query.get('cart') === 'visible'

    const renderNavigation = (
        <>
            {resourceTypes.map((r) => (
                <Box
                    key={r.name}
                    minWidth="166px"
                    marginBottom="15px"
                    marginRight="10px"
                >
                    <LinkTile
                        showLabelWithin
                        icon={
                            <Icon
                                name={r.icon.large}
                                color={
                                    r.name === currentResourceType.name
                                        ? '#848992'
                                        : '#D2D2D9'
                                }
                            />
                        }
                        label={t(r.title)}
                        active={r.name === currentResourceType.name}
                        count={
                            r.name === currentResourceType && totalCount
                                ? totalCount
                                : ''
                        }
                        onClick={() => history.push(`/manageAccess${r.route}`)}
                    />
                </Box>
            ))}
        </>
    )

    const stickyContentListing = (
        <>
            <div style={{ ...flexCenter }}>
                <Typography
                    style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        color: '#363636',
                    }}
                >
                    {t(currentResourceType.title)}
                </Typography>
                <Typography
                    style={{
                        paddingLeft: '8px',
                        color: '#363636',
                        fontSize: '14px',
                    }}
                >
                    {totalCount > 0 ? ` (${totalCount})` : ' (0)'}
                </Typography>
            </div>

            <Box margin="8px 0px 12px 0px" padding="0px 8px">
                <Divider color="#d2d2d9" />
            </Box>
            <div style={{ ...flexCenter }}>
                <StyledButton
                    label={t('Common_Filters')}
                    subLabel={t('Common_ApplyFilters')}
                    onClick={() => {
                        query.set('filters', 'visible')
                        history.push(
                            `${history.location.pathname}?${query.toString()}`,
                        )
                    }}
                    styles={{
                        '& > span': {
                            display: 'flex ',
                            flexDirection: 'column',
                        },
                    }}
                />

                {myTasksUrl && hasAccessToViewPendingAccess && (
                    <StyledButton
                        label={t('Common_View')}
                        subLabel={t('ManageAccess_PendingAccess')}
                        href={myTasksUrl}
                        styles={{
                            marginLeft: '12px',
                            '& > span': {
                                display: 'flex ',
                                flexDirection: 'column',
                            },
                        }}
                    />
                )}
            </div>
        </>
    )

    const listingScreen = (
        <>
            <StickyContainer>
                <Box maxWidth={maxWidth} width="100%" padding="12px 16px">
                    {stickyContentListing}
                </Box>
            </StickyContainer>
            <ItemsContainer top={124}>
                <CardList
                    fetcher={() => ({ loading, loadingMore, list })}
                    infiniteScrollObervableDiv={infiniteScrollObervableDiv}
                    noItemMessage={
                        <Typography variant="h5" style={{ color: '#b4b4b4' }}>
                            {t('Common_NoItemsFound', {
                                itemType: t(currentResourceType.title),
                            })}
                        </Typography>
                    }
                    renderItem={(item, index) => (
                        <div
                            key={`${getUniqueId(item)}-${index} `}
                            style={{
                                padding: '8px',
                                height: 'max-content',
                                width: '100%',
                            }}
                        >
                            <AccessItem
                                resourceType={currentResourceType.name}
                                cardStyles={{
                                    maxWidth: '420px',
                                    margin: 'auto',
                                    '&:hover': {
                                        border: '1px solid #01ae8f !important',
                                    },
                                }}
                                buttonStyles={{
                                    '&:hover': {
                                        backgroundColor: '#02997E',
                                    },
                                }}
                                attributes={resourceTypeAttributes}
                                cardHeaderStyles={{}}
                                item={item}
                                onItemClick={() => {
                                    query.set('selected', getUniqueId(item))
                                    history.push(
                                        `${
                                            history.location.pathname
                                        }?${query.toString()}`,
                                    )
                                }}
                                color={{
                                    primary: '#01ae8f',
                                }}
                            />
                        </div>
                    )}
                />
            </ItemsContainer>
        </>
    )

    const itemDetailsScreen = (
        <>
            <StickyContainer
                color="#ffffff"
                styles={{
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
                    height: '70px',
                    padding: '12px 0px',
                }}
            >
                <Box
                    maxWidth={maxWidth}
                    width="100%"
                    display="flex"
                    justifyContent="center"
                >
                    <StyledButton
                        onClick={() => {
                            query.delete('selected')
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                        border=" 1px solid #307fc1"
                        styles={{
                            color: '#307fc1',
                            fontSize: '14px',
                        }}
                        startIcon={
                            <ArrowIcon
                                style={{ transform: 'rotate(-90deg)' }}
                                color="#307fc1"
                            />
                        }
                    >
                        {t('Common_BackTo', {
                            text: t(currentResourceType.title),
                        })}
                    </StyledButton>
                </Box>
            </StickyContainer>
            <ItemsContainer top={86}>
                {id &&
                    list &&
                    getResourceItemFromList(
                        id,
                        list,
                        currentResourceType.name,
                    ) && (
                        <AccessItemDrawer
                            type={currentResourceType.name}
                            item={getResourceItemFromList(
                                id,
                                list,
                                currentResourceType.name,
                            )}
                            attributes={resourceTypeAttributes}
                            toggleDrawer={() => {
                                query.delete('selected')
                                history.push(
                                    `${
                                        history.location.pathname
                                    }?${query.toString()}`,
                                )
                            }}
                            color={{
                                primary: '#01ae8f',
                            }}
                        />
                    )}
            </ItemsContainer>
        </>
    )

    const filtersStickyControl = (
        <>
            <StickyContainer
                color="#ffffff"
                styles={{
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
                    overflow: 'hidden',
                    padding: '8px 0px',
                    position: 'sticky',
                    top: 0,
                }}
            >
                <Box maxWidth={maxWidth} width="100%">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        padding="0px 12px"
                    >
                        <Typography
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: '#363636',
                            }}
                        >
                            {t(currentResourceType.title)}
                            {totalCount > 0 ? ` (${totalCount})` : ' (0)'}
                        </Typography>

                        <Box margin="10px 0px">
                            <StyledButton
                                onClick={() => {
                                    query.delete('filters')
                                    history.push(
                                        `${
                                            history.location.pathname
                                        }?${query.toString()}`,
                                    )
                                }}
                                border="none"
                                styles={{
                                    color: '#ffffff',
                                    backgroundColor: '#ff5e65',
                                    width: '120px',
                                    '&:hover': {
                                        backgroundColor: '#ff5e65',
                                        boxShadow: 'none',
                                    },
                                }}
                            >
                                {t('Common_ApplyFilters')}
                            </StyledButton>
                        </Box>
                    </Box>
                </Box>
            </StickyContainer>
        </>
    )

    const filtersScreen = (
        <>
            {filtersStickyControl}

            <ItemsContainer>
                <SwipeableContainer>{renderNavigation}</SwipeableContainer>

                {hasAccessToTargetPersonSelection && <ShoppingFor />}

                {currentResourceType.showDirectAssignedOnlyFilter && (
                    <ExpansionPanel
                        title={t('Common_ShowDirectAssignedOnly')}
                        expanded={true}
                    >
                        <Box padding="20px 20px 20px 20px ">
                            <ToggleSwitch
                                label={t('Common_ShowDirectAssignedOnly')}
                                value={manageAccess.showDirectAssignedOnly}
                                onChange={(_, value) =>
                                    dispatch({
                                        type: 'SET_MANAGE_ACCESS_FILTERS',
                                        payload: {
                                            showDirectAssignedOnly: value,
                                        },
                                    })
                                }
                            />
                        </Box>
                    </ExpansionPanel>
                )}

                {currentResourceType.showTimeConstrainedFilter && (
                    <EidFilter
                        resourceType={currentResourceType.name}
                        title={t('ManageAccess_ShowTimeConstrained')}
                        colorCode={{
                            primary: 'rgb(249, 64, 0)',
                            secondary: '#ffffff',
                        }}
                        type="checkbox"
                        label={t('ManageAccess_ShowTimeConstrained')}
                        value={manageAccess.showTimeConstrained}
                        onChange={(value) =>
                            dispatch({
                                type: 'SET_MANAGE_ACCESS_FILTERS',
                                payload: { showTimeConstrained: value },
                            })
                        }
                    />
                )}

                {hasAccessToAccountStoreSelection && <AccountStoreFilter />}
            </ItemsContainer>
        </>
    )

    return (
        !cartOpen && (
            <>
                <Box display={showFilters ? '' : 'none'}>{filtersScreen}</Box>
                <Box display={!showFilters ? '' : 'none'}>
                    <Box display={!id ? '' : 'none'}>{listingScreen}</Box>
                    <Box display={id ? '' : 'none'}>{itemDetailsScreen}</Box>
                </Box>
            </>
        )
    )
}

export default withRouter(MobileVersion)
