import { FC, Fragment, useState } from 'react'
import {
    Box,
    createStyles,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@material-ui/core'
import { Button } from 'components/ApplicationBanner/Button'
import { PersonDetails } from 'components/ApplicationBanner/PersonDetails'
import { useTranslation } from 'react-i18next'
import { ApplicationImage } from 'components/DynamicComponents/ApplicationImage'
import classNames from 'classnames'
import { Loader, Tooltip } from 'packages/eid-ui'
import { isNilOrEmpty, useQuery } from 'packages/core'
import { useHandleSelectApplication, usePerson } from 'hooks'
import { useHistory } from 'react-router'

const tooltipStyles = {
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    padding: '8px',
    maxWidth: '500px',
}

export interface TableViewProps {
    data?: any
    attributes?: any
    fallbackMessage?: any
    onItemClick?: any
    loading?: any
    onSort?: any
    sortBy?: any
    sortOrder?: any
    selectedApplicationId?: any
    currentResourceType?: any
}

var getValue = (ci: any, a: any) => {
    return ci[a.name] ?? ''
}

const colors = {
    primary: '#307fc1',
    grey: '#7d7c7c',
}

const maxCharacters = 80

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            padding: '16px',
        },
        headGrey: {
            color: '#7d7c7c !important',
        },

        blue: {
            color: colors.primary,
        },
        boldBlue: {
            color: colors.primary,
            fontWeight: 'bold',
        },
        underline: {
            textDecoration: 'underline',
        },

        table: {
            backgroundColor: '#ffffff',
            '& tr:hover': {
                backgroundColor: '#f7f8fa',
            },
        },
        tableRow: {
            border: ' solid 1px #efeff1',
        },
        highlightedRow: {
            border: ' solid 1px #01ae8f',
            boxShadow: '4px 4px 15px 0 rgba(48, 127, 193, 0.15)',
        },

        tableCell: {
            maxWidth: '200px',
            overflow: 'hidden',
            padding: '15px 10px',
            fontSize: '13px',
            borderBottom: '0',
        },
        buttonCell: {
            maxWidth: '290px',
        },
        tableCellHighlighted: {
            borderTop: ' solid 1px #01ae8f',
            padding: '15px 10px',
            fontSize: '13px',
            borderBottom: '0',
            '&:first-child': {
                padding: '15px 20px',
            },
        },
        tableHeaderCell: {
            maxWidth: '200px',
            overflowWrap: 'break-word',
            wordBreak: 'normal',
            overflow: 'hidden',
            lineHeight: '15px',
            fontSize: '13px !important',
            backgroundColor: '#fbfbfd',
            '& svg': {
                margin: '0px !important',
            },
        },
        rowSelect: {
            borderBottom: '1px solid grey',
            borderRadius: '0',
        },
        tablePagination: {
            color: 'grey !important',
        },

        tableSortLabel: {
            color: '#7d7c7c !important',
            fill: '#7d7c7c !important',

            '& svg': {
                color: `#D2D2D9 !important`,
                marginLeft: '4px !important',
            },

            '&:hover': {
                color: `${colors.primary} !important`,

                '& svg': {
                    color: `${colors.primary} !important`,
                },
            },
        },
        activeTableSortLabel: {
            '& svg': {
                color: `${colors.primary} !important`,
            },
        },

        bold1: {
            color: '#000000',
            fontSize: '15px',
            fontWeight: 'bold',
        },

        button: {
            minWidth: '280px',
            display: 'flex',
            justifyContent: 'center',
            '& >button': {
                backgroundColor: 'transparent',
                color: '#01ae8f',

                border: 'solid 1px #01ae8f',

                '&:hover': {
                    backgroundColor: '#01ae8f',
                    color: theme.palette.common.white,
                },
            },
        },
        cellContent: {
            display: 'block',
            maxHeight: '200px',
            maxWidth: '200px',
            overflow: 'auto',
            wordBreak: 'break-word',
            wordWrap: 'break-word',
        },
        headerCellLabel: {
            display: 'flex',
        },
    }),
)

const ApplicationOwner = (data: any) => {
    const { data: item } = data

    const [enabled, setEnabled] = useState(false)
    const { data: person } = usePerson(item.ownerId, enabled)

    if (item && item.ownerIsPerson) {
        return (
            <div
                onMouseEnter={() => {
                    setEnabled(true)
                }}
            >
                <PersonDetails
                    size="medium"
                    person={{
                        imageThumbUrl: item.ownerImageThumbUrl,
                        friendlyName: item.ownerFriendlyName,
                        email: person && person.email,
                        telephone: person && person.telephone,
                    }}
                />
            </div>
        )
    }

    return (
        <Fragment>
            {!item.ownerIsPerson && item.ownerFriendlyName
                ? item.ownerFriendlyName
                : '-'}
        </Fragment>
    )
}

const renderComponent = (attribute: any, data: any) => {
    let componentToRender

    if (attribute.component === 'applicationImage') {
        componentToRender = (
            <ApplicationImage attribute={attribute} data={data} />
        )
    } else if (attribute.component === 'owners') {
        const owners = data[attribute.name]

        componentToRender =
            isNilOrEmpty(owners) || owners.length === 0 ? (
                <Fragment>-</Fragment>
            ) : (
                <PersonDetails size="medium" person={owners && owners[0]} />
            )
    } else if (attribute.component === 'applicationOwner') {
        componentToRender = <ApplicationOwner data={data} />
    }

    return <Fragment>{componentToRender}</Fragment>
}

export const TableView: FC<TableViewProps> = (props) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const {
        fallbackMessage,
        loading,
        data,
        attributes,
        onSort,
        sortBy,
        sortOrder,
        selectedApplicationId,
        currentResourceType,
    } = props

    const handleSort = (data: any) => {
        const sortingProperty =
            data.columnName && data.columnName.length > 0
                ? data.columnName
                : data.name

        onSort(
            data,
            sortBy !== sortingProperty
                ? 'desc'
                : !sortOrder || sortOrder === '' || sortOrder === 'asc'
                ? 'desc'
                : 'asc',
        )
    }

    const headings = attributes.map((a: any) => ({
        ...a,
        label: t(a.label),
        sort: a.sortable ? a.sortable : false,
    }))

    const renderTooltipSortLabel: (heading: any) => string = (heading) => {
        const sortingProperty =
            heading.columnName && heading.columnName.length > 0
                ? heading.columnName
                : heading.name
        return sortBy === sortingProperty && sortOrder === 'desc'
            ? t('Common_SortAscending')
            : t('Common_SortDescending')
    }

    const renderLabelWithSortIcon = (heading: any) => {
        const sortingProperty =
            heading.columnName && heading.columnName.length > 0
                ? heading.columnName
                : heading.name
        return (
            <Box component="span" className={classes.headerCellLabel}>
                <Tooltip title={renderTooltipSortLabel(heading)}>
                    <TableSortLabel
                        active={heading.sort}
                        direction={
                            sortBy === sortingProperty ? sortOrder : 'asc'
                        }
                        className={
                            sortBy !== sortingProperty
                                ? classes.tableSortLabel
                                : classNames(
                                      classes.tableSortLabel,
                                      classes.activeTableSortLabel,
                                  )
                        }
                        onClick={() => handleSort(heading)}
                    >
                        {heading.label}
                    </TableSortLabel>
                </Tooltip>
            </Box>
        )
    }

    const tableHeader = (
        <TableHead>
            <TableRow className={classes.tableRow}>
                {headings.map((heading: any, index: any) => (
                    <TableCell
                        key={heading.label}
                        className={classNames(
                            classes.tableCell,
                            classes.headGrey,
                            classes.tableHeaderCell,
                        )}
                    >
                        {!heading.sort ? (
                            <Box
                                component="span"
                                className={classes.headerCellLabel}
                            >
                                {heading.label}
                            </Box>
                        ) : (
                            renderLabelWithSortIcon(heading)
                        )}
                    </TableCell>
                ))}

                <TableCell
                    className={classNames(
                        classes.tableHeaderCell,
                        classes.tableCell,
                        classes.headGrey,
                    )}
                />
            </TableRow>
        </TableHead>
    )

    return (
        <Table id="identities_list_table" className={classes.table}>
            {tableHeader}

            <TableBody>
                {loading && (
                    <TableRow>
                        <TableCell colSpan={headings.length + 1} align="center">
                            <Loader />
                        </TableCell>
                    </TableRow>
                )}

                {data &&
                    (data.length === 0 ? (
                        <TableRow className={classes.tableRow}>
                            <TableCell
                                colSpan={headings.length + 1}
                                align="center"
                            >
                                {fallbackMessage}
                            </TableCell>
                        </TableRow>
                    ) : (
                        <Fragment>
                            {data.map((item: any) => (
                                <SingleApplication
                                    key={item.id}
                                    item={item}
                                    attributes={attributes}
                                    currentResourceType={currentResourceType}
                                    selectedApplicationId={
                                        selectedApplicationId
                                    }
                                />
                            ))}
                        </Fragment>
                    ))}
            </TableBody>
        </Table>
    )
}

const SingleApplication: FC<any> = (props) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const history = useHistory()
    const query = useQuery()
    const {
        item,
        attributes,
        selectedApplicationId,
        currentResourceType,
    } = props

    const { selectApplication } = useHandleSelectApplication()

    const renderCellContent = (a: any, item: any) => {
        const cellContent = (
            <Box className={classes.cellContent}>
                {getValue(item, a).length > maxCharacters
                    ? `${getValue(item, a).substring(0, maxCharacters)}...`
                    : getValue(item, a)}
            </Box>
        )

        return getValue(item, a).length > maxCharacters ? (
            <Tooltip
                title={getValue(item, a)}
                color="#5D6870"
                fontColor="#ffffff"
                enterDelay={500}
                enterNextDelay={500}
                interactive={true}
                tooltipStyles={tooltipStyles}
            >
                {cellContent}
            </Tooltip>
        ) : (
            cellContent
        )
    }

    return (
        <TableRow className={classes.tableRow}>
            {attributes.map((a: any) => {
                let cellContent

                if (!a.component) {
                    cellContent = renderCellContent(a, item)
                } else {
                    cellContent = renderComponent(a, item)
                }
                return (
                    <TableCell
                        key={`${item.id}${a.name}`}
                        className={classes.tableCell}
                    >
                        {cellContent}
                    </TableCell>
                )
            })}

            <TableCell
                className={classNames(classes.tableCell, classes.buttonCell)}
            >
                <Box className={classes.button}>
                    <Button
                        onClick={(event: any) => {
                            query.set('selected', item.id)
                            history.push(
                                `/${
                                    currentResourceType.name
                                }?${query.toString()}`,
                            )
                        }}
                        disabled={selectedApplicationId === item.id}
                    >
                        {t('Common_SelectApplication')}
                    </Button>
                </Box>
            </TableCell>
        </TableRow>
    )
}

TableView.defaultProps = {}
