import { useState } from 'react'
import { Box, styled } from '@material-ui/core'
import { Loader } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import {
    useCart,
    useComputerBr,
    useComputerOwners,
    useTargetPerson,
} from 'hooks'
import { ItemDetails, PeopleList } from 'components'
import { useIsSmallScreen } from 'packages/core'
import cartHelpers from 'containers/Cart/cartHelpers'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { RenewRevoke } from 'components/RenewRevoke'
import { CommonAttributes } from '../CommonAttributes'
import { Skeleton } from '@material-ui/lab'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const OverviewSection = ({ item }) => {
    const computer = item.resource
    const { t } = useTranslation()

    const [targetPerson] = useTargetPerson()

    const { data: businessRequestItem, isLoading } = useComputerBr(
        targetPerson.id,
        item.assignment.resourceAssignment.resourceId,
        item.assignment.resourceAssignment.resourceTypeId,
        item.assignment.resourceAssignment.resourceAssignmentId,
    )

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_DisplayName')}
                    value={computer.computerFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_DnsHostName')}
                    value={computer.dnsHostName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_ComputerType')}
                    value={computer.computerTypeName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_DomainOrDirectory')}
                    value={computer.resourceSystemFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_OperatingSystem')}
                    value={computer.operatingSystem}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_PsmEnabled')}
                    value={computer.description}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_JitEnabled')}
                    value={computer.description}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                {isLoading && <Skeleton height={32} />}

                <CommonAttributes
                    item={item}
                    businessRequestItem={businessRequestItem}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}

const AdditionalInformationSection = ({ item }) => {
    const computer = item.resource
    const { t } = useTranslation()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const { data: owners, isLoading: ownersLoading } = useComputerOwners(
        computer.id,
    )

    return (
        <>
            {ownersLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <ItemDetails.AttributesContainer bottomPadding="0">
                    <PaddedDiv>
                        <ItemDetails.Attribute
                            label={t('Common_Owners')}
                            value={<PeopleList list={owners} />}
                            orientation={attributeOrientation}
                        />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            )}
        </>
    )
}

const ComputerDetails = ({ item, toggleDrawer }) => {
    const { t } = useTranslation()
    const [targetPerson] = useTargetPerson()

    const registry = useRegistry()

    // const mailboxType = registry.get(ResourceTypesNamespace, 'Mailboxes')

    const [activeTab, setActiveTab] = useState('overview')

    // const preRevoke = () => {
    //     const itemToRevoke = cartHelpers.mailboxToCartItem({
    //         targetPerson,
    //         assignmentType: 'Remove',
    //         computer: item.resource,
    //         assignmentStatus: item.assignment,
    //     })
    //     if (!itemToRevoke) return undefined

    //     return itemToRevoke
    // }

    // const preRenew = (baseObj) => {
    //     const itemToRenew = cartHelpers.mailboxToCartItem({
    //         targetPerson,
    //         assignmentType: 'Add',
    //         computer: item.resource,
    //         assignmentStatus: item.assignment,
    //         ...baseObj,
    //     })
    //     if (!itemToRenew) return undefined

    //     return itemToRenew
    // }

    // const { data: cart } = useCart()

    // if (!cart) return <Loader />

    return (
        <>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />

                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
            </ItemDetails.Tabs>

            {activeTab === 'overview' && <OverviewSection item={item} />}

            {activeTab === 'additionalInformation' && (
                <AdditionalInformationSection item={item} />
            )}

            {/* <RenewRevoke
                resourceType={mailboxType}
                resource={item.resource}
                assignment={item.assignment}
                preRenew={preRenew}
                postRenew={toggleDrawer}
                preRevoke={preRevoke}
                postRevoke={toggleDrawer}
            /> */}
        </>
    )
}

export default ComputerDetails
