import { Drawer, Loader } from 'packages/eid-ui'
import ItemHeader from '../ItemHeader'
import { isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import { useApplication, useCheckApplicationsAccess, useTargetPerson } from 'hooks'
import ItemsDetailsMobileWrapper from 'components/ItemsDetailsMobileWrapper'
import ApplicationDetails from './ApplicationDetails'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

const ApplicationsDrawer = ({ id, toggleDrawer, item, currentResourceType }) => {
    const history = useHistory()
    const isManageAccess = history.location.pathname === '/manageAccess/applications';
    const applicationResourceId = isManageAccess ? item.resource.id : id
    const [accessLevel, setAccessLevelValue] = useState(null)
    const isSmallScreen = useIsSmallScreen()
    const [targetPerson] = useTargetPerson()
    const { data } = useApplication(applicationResourceId)
    const {
        data: accessLevels,
        isLoading: isCheckingAccess,
    } = useCheckApplicationsAccess(applicationResourceId, targetPerson.id)

    useEffect(() => {
        if (accessLevel && accessLevels && accessLevels.length === 1) {
            setAccessLevelValue(accessLevels[0])
        }
    }, [accessLevels, setAccessLevelValue])
    return isSmallScreen ? (
        <ItemsDetailsMobileWrapper toggleDrawer={toggleDrawer}>
            <ItemHeader title={data?.friendlyName} />
            {!data && <Loader />}
            {data && (
                <ApplicationDetails
                    toggleDrawer={toggleDrawer}
                    application={data}
                    accessLevel={accessLevel}
                    accessLevels={accessLevels}
                    setAccessLevelValue={(val) =>
                        setAccessLevelValue(val)
                    }
                    isCheckingAccess={isCheckingAccess}
                    targetPerson={targetPerson}
                    applicationId={applicationResourceId}
                    isManageAccess={isManageAccess}
                    item={item}
                    currentResourceType={currentResourceType}
                />
            )}
        </ItemsDetailsMobileWrapper>
    ) : (
        <Drawer
            width="768px"
            open={!isNilOrEmpty(applicationResourceId)}
            toggleDrawer={toggleDrawer}
            stickyHeader
            closeOnOutsideClick
            header={<ItemHeader title={data?.friendlyName} />}
        >
            {!data && <Loader />}
            {data && (
                <ApplicationDetails
                    toggleDrawer={toggleDrawer}
                    application={data}
                    accessLevel={accessLevel}
                    accessLevels={accessLevels}
                    setAccessLevelValue={(val) =>
                        setAccessLevelValue(val)
                    }
                    isCheckingAccess={isCheckingAccess}
                    targetPerson={targetPerson}
                    applicationId={applicationResourceId}
                    isManageAccess={isManageAccess}
                    item={item}
                    currentResourceType={currentResourceType}
                />
            )}
        </Drawer>
    )
}

export default ApplicationsDrawer
