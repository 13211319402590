import applicationRoles from './applicationRoles'
import azureLicenses from './azureLicenses'
import azureRoles from './azureRoles'
import businessRoles from './businessRoles'
import mailboxes from './mailboxes'
import manageAccess from './manageAccess'
import managementRoles from './managementRoles'
import sharedFolders from './sharedFolders'
import applications from './applications'
import computers from './computers'
import credentials from './credentials'

const appConfiguration = {
    manageAccess: manageAccess,
    resourceTypes: [
        applicationRoles,
        azureLicenses,
        azureRoles,
        businessRoles,
        mailboxes,
        managementRoles,
        sharedFolders,
        applications,
        computers,
        credentials,
    ],
}

export default appConfiguration
