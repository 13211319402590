import { useState } from 'react'
import { useAppState } from 'appContext'
import { Icon } from 'packages/eid-icons'
import { Button } from 'components'
import { PasswordModal } from '../../components/PasswordModal'

const MasterPasswordLock = ({ style }) => {
    const [{ masterPassword }] = useAppState()
    const [masterPasswordOpen, setMasterPasswordOpen] = useState('')

    if (masterPassword) return <></>

    return (
        <PasswordModal
            open={masterPasswordOpen}
            setOpen={setMasterPasswordOpen}
        >
            <div
                style={{
                    position: 'absolute',
                    display: 'flex',
                    background: 'rgb(48,127,193,0.3)',
                    zIndex: 1,
                    ...style,
                }}
            >
                <Button
                    style={{
                        height: 20,
                        background: '#262fa1',
                        color: 'white',
                        fontSize: 14,
                        padding: 5,
                        margin: 'auto',
                        '&:hover': {
                            background: '#262fa1',
                        },
                    }}
                    startIcon={
                        <Icon
                            style={{
                                height: 15,
                                width: 12,
                                marginRight: '-4px',
                                marginLeft: '2px',
                            }}
                            name={'Lock'}
                        ></Icon>
                    }
                    onClick={() =>
                        setMasterPasswordOpen(
                            masterPassword ? 'addToCart' : 'enterPassword',
                        )
                    }
                >
                    UNLOCK
                </Button>
            </div>
        </PasswordModal>
    )
}

export default MasterPasswordLock
