import { useState } from 'react'
import { Box, Button as MuiButton } from '@material-ui/core'
import { Tooltip, useNotification } from 'packages/eid-ui'
import { useCredentialHiddenAttributes } from 'hooks'
import { Spinner } from 'packages/eid-ui'
import { TextFormatter } from 'components'
import { useTranslation } from 'react-i18next'

const LockedPasswordBox = ({
    targetUrl,
    targetProperty,
    isClickable,
    validationMessage,
}) => {
    const [getCredentialHiddenProps] = useCredentialHiddenAttributes()
    const { t } = useTranslation()

    const { showSuccessMessage, showWarningMessage } = useNotification()
    const [fetchedValue, setFetchedValue] = useState()
    const [isRevealed, setIsRevealed] = useState(false)
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false)
    const [openPopover, setOpenPopover] = useState(false)

    const showPopover = () => {
        setOpenPopover(true)
    }

    const hidePopover = () => {
        setOpenPopover(false)
    }

    const handleCopy = async () => {
        setShowLoadingSpinner(true)
        let apiResponseValue = await getCredentialHiddenProps(targetUrl)
        let result = apiResponseValue[targetProperty]
        hidePopover()
        if (result) {
            navigator.clipboard.writeText(result)

            showSuccessMessage(t('CopiedToClipboard'))
        } else {
            showWarningMessage(t('FailedToCopy'))
        }
        setShowLoadingSpinner(false)
    }

    const toogleReveal = async () => {
        if (isRevealed) {
            setIsRevealed(!isRevealed)
            return
        }
        setShowLoadingSpinner(true)
        let apiResponseValue = await getCredentialHiddenProps(targetUrl)
        let result = apiResponseValue[targetProperty] || '-'
        hidePopover()

        setTimeout(() => {
            setIsRevealed(!isRevealed)
        }, [100])
        setFetchedValue(result)

        setShowLoadingSpinner(false)
    }

    if (showLoadingSpinner) {
        return (
            <Box display="flex">
                <Spinner />
            </Box>
        )
    }
    if (!isClickable) {
        return (
            <Tooltip
                placement="top-start"
                title={validationMessage}
                arrow={false}
            >
                <Box display="flex" style={{ cursor: 'pointer' }}>
                    {'*'.repeat(5)}
                </Box>
            </Tooltip>
        )
    }
    return (
        <Box
            style={{ cursor: 'pointer' }}
            onMouseEnter={showPopover}
            onMouseLeave={hidePopover}
        >
            <div
                style={{
                    background: '#262fa1',
                    marginTop: '-30px',
                    display: openPopover ? 'flex' : 'none',
                    width: 'fit-content',
                    position: 'absolute',
                    zIndex: 999,
                }}
                onMouseLeave={hidePopover}
            >
                <MuiButton
                    style={{ color: 'white' }}
                    size="small"
                    variant="text"
                    onClick={handleCopy}
                >
                    {t('Copy')}
                </MuiButton>
                <MuiButton
                    style={{ color: 'white' }}
                    size="small"
                    variant="text"
                    onClick={toogleReveal}
                >
                    {isRevealed ? t('Hide') : t('Reveal')}
                </MuiButton>
            </div>
            <TextFormatter
                style={{ fontSize: '13px' }}
                value={
                    isRevealed
                        ? fetchedValue
                            ? fetchedValue
                            : '-'
                        : '*'.repeat(5)
                }
                maxCharacters="100"
            />
        </Box>
    )
}

export default LockedPasswordBox
