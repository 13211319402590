import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Radio from '@material-ui/core/Radio'
import { Box, styled, makeStyles, List, ListItem, ListItemText } from '@material-ui/core'
import { Loader, Tooltip } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useCart, useAccessRequestPolicy, useTargetPerson } from 'hooks'
import { ItemDetails } from 'components'
import cartHelpers from 'containers/Cart/cartHelpers'
import { AddToCart } from './AddToCart'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { OverviewSection } from './OverviewSection'
import { MoreInformationSection } from './MoreInformationSection'
import { PasswordModal } from 'components/PasswordModal'
import { useAppState } from 'appContext'
import { useTimeConstrainedControl } from '../../ListingPage/Computers/TimeConstrainedControl'



const StyledRadioIcon = styled(Radio)({
    padding: '0px 12px 0px 0px',
})

const tooltipStyles = {
    top: '5px',
}


const useStyles = makeStyles({
    root: {
        width: '100%',
        maxHeight: '208px',
        overflow: 'auto',
        borderRadius: '5px',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            margin: '4px',
            '-webkit-border-radius': '3px',
            borderRadius: '3px',
            backgroundColor: '#f7f7f7',
        },

        '&::-webkit-scrollbar-thumb': {
            width: '3px',
            borderRadius: '5px',
            border: '2px solid transparent',
            height: '20px',
            backgroundColor: '#d8d8d8',
        },
        backgroundColor: '#ffffff',
        '& .MuiListItem-root': {
            cursor: 'pointer',
            width: '100%',
            height: '40px',
            wordBreak: 'break-word',
            paddingTop: '10px',
            fontSize: '14px',
            color: '#767676',
            '&.Mui-selected': {
                color: '#000000',
                backgroundColor: 'transparent',
            },
        },
    },
    sectionHeader: {
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        textTransform: 'uppercase',
        color: '#000000 !important',
        overflow: 'hidden',
        fontSize: '14px',
        fontWeight: 'bold',
        '& svg': {
            margin: '0px !important',
        },
    }
})

const PromptContainer = styled('div')({
    padding: '48px 31px 20px 31px',
    minHeight: '232px',
    display: 'flex',
})

const ONE_TIME_ACCESS = 'One Time Access'
const PRE_APPROVED_ACCESS = 'Pre-Approved Access'
const allAccessTypes = [
    {
        id: 1,
        friendlyName: ONE_TIME_ACCESS,
        fullPath: 'Allows you request One Time',
    },
    {
        id: 2,
        friendlyName: PRE_APPROVED_ACCESS,
        fullPath: 'Allows you to request PreApproved',
    },
]

const CredentialDetails = ({ credential, toggleDrawer }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [targetPerson] = useTargetPerson()

    const { data: cart } = useCart()

    const [activeTab, setActiveTab] = useState('overview')
    const [open, setOpen] = useState('')
    const [{ masterPassword }] = useAppState()
    const [accessType, setAccessType] = useState(allAccessTypes[0])


    const requestPolicy = useAccessRequestPolicy(credential.requestPolicyId)

    const {
        selectedStartDate,
        selectedEndDate,
        hasInvalidDates,
        timeConstrainedControl,
    } = useTimeConstrainedControl(requestPolicy, true)


    const registry = useRegistry()

    const credentialType = registry.get(ResourceTypesNamespace, 'Credentials')

    const getPreValidationMessage = () => {
        if (!Boolean(accessType)) {
            return t('Credential_SelectAccessType')
        } else if (
            accessType?.friendlyName === ONE_TIME_ACCESS &&
            hasInvalidDates
        )
            return t('Common_InvalidDates')
        else return ''
    }
    const preAdd = (baseObj) => {
        const itemToAdd = cartHelpers.sharedCredentialToCartItem({
            targetPerson,
            assignmentType: 'Add',
            credential,
            timeConstrained: accessType?.friendlyName === ONE_TIME_ACCESS,
            startDateUtc:
                accessType?.friendlyName === ONE_TIME_ACCESS
                    ? selectedStartDate
                    : null,
            endDateUtc:
                accessType?.friendlyName === ONE_TIME_ACCESS
                    ? selectedEndDate
                    : null,
            ...baseObj,
        })

        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const accessTypeSelectorSection = (
        <PromptContainer>
            <Box
                width="172px"
                display="flex"
                flexDirection="column"
                zIndex={1}
            >
                <Box className={classes.sectionHeader}>{t('Common_SelectAccessType')}</Box>
                <Icon
                    style={{ marginTop: '20px' }}
                    name='MonitorClock'
                    height="150px"
                    width="150px"
                    color="#D2D2D9"
                />
            </Box>
            <Box width="40%" paddingLeft="16px">
                <List classes={classes}>
                    {allAccessTypes.map((i, index) => (
                        <ListItem
                            selected={i.id === accessType?.id}
                            key={`${i.id}${index}`}
                            onClick={() => setAccessType(i)}
                        >
                            <StyledRadioIcon
                                icon={<Icon name="CheckBox" />}
                                checkedIcon={<Icon name="CheckedBox" />}
                                checked={i.id === accessType?.id}
                            />
                            <ListItemText disableTypography primary={i.friendlyName} />
                            {<Tooltip
                                tooltipStyles={tooltipStyles}
                                placement="top"
                                arrow={true}
                                title={i.fullPath || ''}
                            >
                                <Box><Icon name="InfoNew" /></Box>
                            </Tooltip>}
                        </ListItem>
                    ))}
                </List>
            </Box>
        </PromptContainer>

    )

    if (!cart) return <Loader />

    return (
        <Fragment>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
            </ItemDetails.Tabs>

            {activeTab === 'overview' && (
                <OverviewSection credential={credential} />
            )}
            {activeTab === 'additionalInformation' && (
                <MoreInformationSection credential={credential} />
            )}

            {accessTypeSelectorSection}

            {!masterPassword ? (
                <PasswordModal open={open} setOpen={setOpen} />
            ) : (
                <Box display="flex" flexDirection="row" flexWrap={'wrap'}>
                    <AddToCart
                        resource={credential}
                        resourceType={credentialType}
                        secondary={accessType}
                        preValidate={getPreValidationMessage}
                        preAdd={preAdd}
                        postAdd={toggleDrawer}
                    />
                    {accessType.friendlyName === ONE_TIME_ACCESS &&
                        timeConstrainedControl}
                </Box>
            )}
        </Fragment>
    )
}

export default CredentialDetails
