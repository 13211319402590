import React, { useState } from 'react'
import { Loader } from 'packages/eid-ui'
import {
    MenuItem,
    makeStyles,
    ListItemText,
    ListItemIcon,
    Paper,
} from '@material-ui/core'
import { useHistory } from 'react-router'
import { useQuery } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import { useUiActionsByResource } from 'hooks'

const useMenuStyles = makeStyles({
    root: {
        fontSize: '16px',
        position: 'relative',
        minHeight: '40px',
        paddingLeft: '30px',
        width: '100%',
        borderLeft: '2px solid transparent',
        '&:hover': {
            backgroundColor: 'rgba(48, 127, 193, 0.07) !important',
            borderLeft: '2px solid #307fc1',
            '& svg': {
                opacity: 1,
                filter: 'grayscale(0%) ',
                color: 'blue',
            },
        },
    },
    icon: {
        minWidth: '40px',
        color: 'black',
        '& svg': {
            height: '16px',
            width: '16px',
        },
    },
})

const ActionsList = ({ workflows, resourceId, route }) => {
    const classes = useMenuStyles()

    const query = useQuery()
    const history = useHistory()

    const handleClick = (name) => {
        query.set('workflowName', name)
        query.set('resourceID', `${resourceId}`)
        query.delete('selected')
        history.push(`/workflows/${route}?${query.toString()}`)
    }

    return (
        <>
            {workflows &&
                workflows.map((d, i) => (
                    <MenuItem
                        onClick={() => handleClick(d.requestWorkflowName)}
                        classes={classes}
                        key={i}
                    >
                        <ListItemIcon className={classes.icon}>
                            <Icon name="Arrow" direction="right" />
                        </ListItemIcon>
                        <ListItemText>{d.friendlyName}</ListItemText>
                    </MenuItem>
                ))}
        </>
    )
}

const WorkflowActions = ({ resource, route }) => {
    const { data, isLoading } = useUiActionsByResource(resource.id)
    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Paper
                    style={{
                        borderRadius: '8px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <ActionsList
                        workflows={data}
                        resourceId={resource.id}
                        route={route}
                    ></ActionsList>
                </Paper>
            )}
        </>
    )
}

export default WorkflowActions
