import { useQuery } from 'react-query'
import { mockApiCall, useAxios } from 'packages/core'
export const COMPUTER_KEY_PREFIX = 'COMPUTERS'

export const useComputer = (id) => {
    const callApi = useAxios()
    return useQuery(
        [COMPUTER_KEY_PREFIX, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/computers/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useComputerOwners = (id) => {
    const callApi = useAxios()
    return useQuery(
        [`${COMPUTER_KEY_PREFIX}_OWNERS`, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/People/resourceOwners?resourceId=${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useComputerBr = (
    targetPersonId,
    resourceId,
    resourceTypeId,
    resourceAssignmentId,
) => {
    const callApi = useAxios()
    return useQuery(
        [
            COMPUTER_KEY_PREFIX,
            'Business_Request',
            targetPersonId,
            resourceId,
            resourceTypeId,
            resourceAssignmentId,
        ],
        () =>
            callApi({
                method: 'GET',
                url: `/api/computers/businessRequestItemAssignmentDetails?targetPersonId=${targetPersonId}&resourceId=${resourceId}&resourceTypeId=${resourceTypeId}&resourceAssignmentId=${resourceAssignmentId}`,
            }).then((data) => data.data),
        {
            enabled:
                Boolean(targetPersonId) &&
                Boolean(resourceId) &&
                Boolean(resourceTypeId) &&
                Boolean(resourceAssignmentId),
        },
    )
}

export const useCheckComputerAccess = (id, targetPersonId) => {
    const callApi = useAxios()
    return useQuery(
        [`${COMPUTER_KEY_PREFIX}_ACCESS`, id, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/Computers/checkAssigmentStatus/${id}/${targetPersonId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id) && Boolean(targetPersonId),
        },
    )
}

export const useComputerRequestableDetailOptions = (id, targetPersonId) => {
    const callApi = useAxios()
    return useQuery(
        [`${COMPUTER_KEY_PREFIX}_REQUESTABLE_DETAIL`, id, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/Computers/computerRequestableDetailOptions/${id}/${targetPersonId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id) && Boolean(targetPersonId),
        },
    )
}
