import { Fragment } from 'react'
import { Box, styled, withTheme } from '@material-ui/core'
import {
    isNilOrEmpty,
    useGetControlsAccess,
    useIsSmallScreen,
    useQuery,
} from 'packages/core'
import { EidFilter, ShoppingFor, TargetSystem } from 'components'
import useConfiguration from 'useConfiguration'
import { useResourceTypeContext } from 'resourceTypeContext'
import { EidWorkflowButton } from 'components/EidWorkflowButton'

const filters = {
    ShoppingFor: ShoppingFor,
    TargetSystem: TargetSystem,
}

const GridItem = styled(withTheme(Box))((props) => ({
    width: '100%',
    [props.theme.breakpoints.up('md')]: {
        padding: '8px',
    },
}))

const Selectors = ({ resourceType, screen }) => {
    const isSmallScreen = useIsSmallScreen()
    const [state] = useResourceTypeContext()

    const { getResourceType } = useConfiguration()
    const accessControlsNames = useGetControlsAccess().map((c) => c.name)

    const currentResourceType = getResourceType(resourceType)

    let filtersToRender = currentResourceType.filters.filter((f) =>
        f.requireAccess
            ? accessControlsNames.indexOf(f.requireAccess.control) >= 0
            : true,
    )

    if (screen === 'RequestAccess') {
        filtersToRender = filtersToRender.filter(
            (f) => f.forRequestAccess === true,
        )
    } else if (screen === 'ManageAccess') {
        filtersToRender = filtersToRender.filter(
            (f) => f.forManageAccess === true,
        )
    } else {
        filtersToRender = []
    }

    const workflowButtonsToRender = currentResourceType.workflows.filter((f) =>
        f.requireAccess
            ? accessControlsNames.indexOf(f.requireAccess.control) >= 0
            : true,
    )

    const query = useQuery()

    const isApplicationsPage = Boolean(query.get('applicationId'))

    if (isApplicationsPage) {
        filtersToRender = filtersToRender.filter(
            (f) => f.name !== 'Applications',
        )
    }

    return (
        <Fragment>
            {workflowButtonsToRender.map((w) => {
                return isSmallScreen ? (
                    <Box marginBottom={1}>
                        <EidWorkflowButton {...w} key={w.name} />
                    </Box>
                ) : (
                    <GridItem key={w.name}>
                        <EidWorkflowButton {...w} />
                    </GridItem>
                )
            })}
            {filtersToRender
                .sort((a, b) => a.order - b.order)
                .map(({ dynamic, ...f }) => {
                    if (dynamic === true) {
                        if (
                            f.showOnly &&
                            isNilOrEmpty(state[f.showOnly.contextProp])
                        ) {
                            return <Fragment key={f.name} />
                        }

                        return isSmallScreen ? (
                            <EidFilter
                                {...f}
                                key={f.name}
                                resourceType={resourceType}
                            />
                        ) : (
                            <GridItem key={f.name}>
                                <EidFilter {...f} resourceType={resourceType} />
                            </GridItem>
                        )
                    } else {
                        const DefinedFilter = filters[f.name]

                        return isSmallScreen ? (
                            <DefinedFilter
                                key={f.name}
                                resourceType={resourceType}
                            />
                        ) : (
                            <GridItem key={f.name}>
                                <DefinedFilter resourceType={resourceType} />
                            </GridItem>
                        )
                    }
                })}
        </Fragment>
    )
}

export default Selectors
