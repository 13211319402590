import React from 'react'
import { isNilOrEmpty } from 'packages/core'
import { EidAdvancedSearch } from 'packages/eid-controls'
import { useResourceTypeContext } from 'resourceTypeContext'
import FormsFilter from './FormsFilter'
import { useTranslation } from 'react-i18next'

const AdvancedFilter = ({ forms, tags, resourceType }) => {
    const { t } = useTranslation()

    const [state, dispatch] = useResourceTypeContext()
    const tagsContextValue = state[tags?.contextProp] ?? []

    const props = {}
    if (!isNilOrEmpty(forms)) {
        props.forms = {
            colorCode: forms.colorCode,
            filters: forms.filters,
        }
    }
    if (!isNilOrEmpty(tags)) {
        props.tags = {
            colorCode: tags.colorCode,
            allTags: state.tags,
            selectedTags: tagsContextValue,
            onTagClick: (tag) => {
                const currentIndex = tagsContextValue.findIndex(
                    (f) => f === tag,
                )
                const newChecked = [...tagsContextValue]

                if (currentIndex === -1) {
                    newChecked.push(tag)
                } else {
                    newChecked.splice(currentIndex, 1)
                }

                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: tags.contextProp,
                        value: newChecked,
                    },
                })
            },
        }
    }

    return (
        <EidAdvancedSearch
            formsFilter={
                <FormsFilter resourceType={resourceType} {...props.forms} />
            }
            formsLabel={t(forms.title)}
            tagsLabel={tags && t(tags.title)}
            loadTags={() =>
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'shouldLoadTags',
                        value: true,
                    },
                })
            }
            tabsContinerStyles={{
                width: 'calc(100% + 40px)',
                marginLeft: '-20px',
                marginTop: '-20px',
            }}
            {...props}
        />
    )
}

export default AdvancedFilter
