import React from 'react'
import { Header } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { useIsSmallScreen } from 'packages/core'
import { useResourceTypeContext } from 'resourceTypeContext'
import useConfiguration from 'useConfiguration'

const Search = ({ history, ...rest }) => {
    const isSmallScreen = useIsSmallScreen()

    const {
        location: { pathname },
    } = history

    const { t } = useTranslation()
    const { resourceTypes } = useConfiguration()

    const [{ searchTerm }, dispatch] = useResourceTypeContext()

    const handleSearch = (searchKey) =>
        dispatch({
            type: 'SET_SEARCH_TERM',
            payload: searchKey,
        })

    const handleSelect = (option) => {
        history.push(option.route)
    }

    const areaName = pathname.split('/')[1].toLowerCase()

    const isActive = (name) => {
        if (areaName !== 'manageAccess'.toLowerCase()) {
            return areaName === name.toLowerCase()
        } else {
            return pathname.split('/')[2].toLowerCase() === name.toLowerCase()
        }
    }

    let requestAccessResourceTypes = []

    requestAccessResourceTypes = requestAccessResourceTypes.concat(
        resourceTypes.map((r) => ({
            title: t(r.title),
            route: r.route,
            icon: r.icon,
            selected: isActive(r.name),
        })),
    )

    const manageAccessResourceTypes = resourceTypes.map((r) => ({
        title: t(r.title),
        route: `/manageAccess${r.route}`,
        icon: r.icon,
        selected: isActive(r.name),
    }))

    const options =
        areaName !== 'manageAccess'.toLowerCase()
            ? requestAccessResourceTypes
            : manageAccessResourceTypes

    return !isSmallScreen ? (
        <Header.SearchMenu
            placeholder={
                areaName !== 'manageAccess'.toLowerCase()
                    ? t('Common_SearchIn', { text: t('Common_RequestAccess') })
                    : t('Common_SearchIn', { text: t('Common_ManageAccess') })
            }
            options={options}
            handleSearch={handleSearch}
            handleSelect={handleSelect}
            value={searchTerm}
            {...rest}
        />
    ) : (
        <Header.Search
            placeholder={
                areaName !== 'manageAccess'.toLowerCase()
                    ? t('Common_SearchIn', { text: t('Common_RequestAccess') })
                    : t('Common_SearchIn', { text: t('Common_ManageAccess') })
            }
            handleSearch={handleSearch}
            value={searchTerm}
            {...rest}
        />
    )
}

export default withRouter(Search)
