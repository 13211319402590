import React from 'react'
import { Box, styled } from '@material-ui/core'
import { useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const OverviewSection = ({ businessRole }: any) => {
    const { t } = useTranslation()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_TechnicalName')}
                    value={businessRole.name}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                {businessRole.businessDomain_FriendlyName && (
                    <ItemDetails.Attribute
                        label={t('BusinessRoles_Domain')}
                        value={businessRole.businessDomain_FriendlyName}
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                )}
                <ItemDetails.Attribute
                    label={t('BusinessRoles_HighLevelClassification')}
                    value={businessRole.highLevelClassification}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={businessRole.description}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}
