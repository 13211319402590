import { FC } from 'react'
import { Box, makeStyles, Typography, Dialog } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { IconButton } from 'packages/eid-ui'

const mobileScreenWidth = '767px'

const useStyles = makeStyles((theme) => ({
    body: (
        {
            // disablePadding
        },
    ) => ({
        backgroundColor: '#f7f8fa',
        borderRadius: '8px',
        //padding: disablePadding ? 0 : '24px 40px',
        padding: '24px 40px',
        '&:focus': {
            outline: 'none',
        },

        [`@media (max-width:${mobileScreenWidth})`]: {
            //padding: disablePadding ? 0 : '24px 16px',
        },
    }),
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        maxHeight: '12%',
        padding: '16px 12px 16px 32px',
        backgroundColor: '#ffffff',
    },
    closeIcon: {
        display: 'flex',
        height: '10px',
    },

    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#3b454d',
    },
    footer: {
        backgroundColor: '#ffffff',
    },
}))

const useDialogStyles = makeStyles(() => ({
    paper: {
        maxWidth: '800px',
        [`@media (max-width:${mobileScreenWidth})`]: {
            margin: '8px ',
            width: 'calc(100% - 16px) !important',
            maxWidth: 'calc(100% - 16px) !important ',
        },
    },
}))

export interface ModalProps {
    open?: any
    onClose?: any
    title?: any
    footerContent?: any
    headerContent?: any
}

export const Modal: FC<ModalProps> = (props) => {
    const classes = useStyles()

    const {
        open,
        onClose,
        title,
        children,
        headerContent,
        footerContent,
    } = props

    const dialogClasses = useDialogStyles()

    return (
        <Dialog
            scroll={'body'}
            open={open}
            onClose={onClose}
            aria-labelledby={title}
            aria-describedby={title}
            classes={dialogClasses}
        >
            <Box className={classes.modalHeader}>
                {!headerContent ? (
                    <Typography className={classes.title}>{title}</Typography>
                ) : (
                    headerContent
                )}

                <Box className={classes.closeIcon}>
                    <IconButton onClick={onClose}>
                        <Icon name="Close" color="#959598" />
                    </IconButton>
                </Box>
            </Box>

            <Box className={classes.body}>{children}</Box>

            <Box className={classes.footer}>{footerContent}</Box>
        </Dialog>
    )
}
