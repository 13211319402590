import React, { useState, Fragment, useEffect } from 'react'
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableSortLabel,
    makeStyles,
    Box,
} from '@material-ui/core'
import Button from './Button'
import clsx from 'clsx'
import { Loader, Tooltip, WarningModal, useNotification } from 'packages/eid-ui'
import TextFormatter from './TextFormatter'
import { useTranslation } from 'react-i18next'
import { getUniqueId } from './resourceTypeHelpers'
import {
    useTargetPerson,
    useAddItemToCart,
    useAccessRequestPolicy,
    useRenewableAssignmentInfo,
    useAllCarts,
} from 'hooks'
import cartHelpers from 'containers/Cart/cartHelpers'
import DynamicComponents from 'components/DynamicComponents'
import { ApplicationImage } from 'components/DynamicComponents/ApplicationImage'
import { CredentialsActionsButton } from './CredentialsActionsButton'
import { useRouteMatch } from 'react-router-dom'
import moment from 'moment'
import { Icon } from 'packages/eid-icons'

const colors = {
    primary: '#307fc1',
    grey: '#7d7c7c',
}
const maxCharacters = 80

const tooltipStyles = {
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    padding: '8px',
    maxWidth: '500px',
}

var getValue = (ci, a) => {
    let value

    switch (a.name) {
        case 'differentiationValue':
            value = ci.assignment.resourceAssignment.locationFriendlyName
            break
        case 'accessLevel':
            value = ci.assignment.resourceAssignment.friendlyName
            break
        case 'assignee':
            value = ci.assignment.resourceAssignment.assigneePreviewName
            break
        default:
            value = ci.resource[a.name]
    }

    return value ?? ''
}

const useStyles = makeStyles({
    headGrey: {
        color: '#7d7c7c !important',
    },

    blue: {
        color: colors.primary,
    },
    boldBlue: {
        color: colors.primary,
        fontWeight: 'bold',
    },
    underline: {
        textDecoration: 'underline',
    },

    table: {
        backgroundColor: '#ffffff',
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
    },
    highlightedRow: {
        border: ' solid 1px #01ae8f',
        boxShadow: '4px 4px 15px 0 rgba(48, 127, 193, 0.15)',
    },

    tableCell: {
        maxWidth: '180px',
        overflow: 'hidden',
        padding: '15px 6px',
        fontSize: '13px',
        borderBottom: '0',
        '&:first-child': {
            padding: '0px 20px',
        },
    },
    tableCellHighlighted: {
        borderTop: ' solid 1px #01ae8f',
        padding: '15px 6px',
        fontSize: '13px',
        borderBottom: '0',
        '&:first-child': {
            padding: '15px 20px',
        },
    },
    hilightFont: {
        fontSize: '15px',
    },
    tableHeaderCell: {
        maxWidth: '180px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '13px !important',
        backgroundColor: '#fbfbfd',
        '& svg': {
            margin: '0px !important',
        },
    },
    tableActionCell: {
        padding: '15px 6px',
        display: 'flex',
        borderBottom: '0',
        '& > button:first-Child': {
            marginRight: '12px',
        },
    },
    rowSelect: {
        borderBottom: '1px solid grey',
        borderRadius: '0',
    },
    tablePagination: {
        color: 'grey !important',
    },

    bold1: {
        color: '#000000',
        fontSize: '15px',
        fontWeight: 'bold',
    },

    tableSortLabel: {
        color: '#7d7c7c !important',
        fill: '#7d7c7c !important',

        '& svg': {
            color: `#D2D2D9 !important`,
            marginLeft: '4px !important',
        },

        '&:hover': {
            color: `${colors.primary} !important`,

            '& svg': {
                color: `${colors.primary} !important`,
            },
        },
    },
    activeTableSortLabel: {
        '& svg': {
            color: `${colors.primary} !important`,
        },
    },
})

const RevokeButton = ({ resourceType, ci }) => {
    const { t } = useTranslation()
    const [showConfirm, setConfirm] = useState(false)
    const [targetPerson] = useTargetPerson()

    const [addItemToCart, { isLoading: isAdding }] = useAddItemToCart()

    const handleRevoke = () => {
        const itemToRevoke = cartHelpers.accessItemToCartItem(
            resourceType !== 'AzureRoles'
                ? resourceType
                : ci.resource.roleGroupType === 'Rbac'
                ? 'AzureRbacRoles'
                : 'AzureAdminRoles',
            targetPerson,
            ci,
            'Remove',
        )
        if (!itemToRevoke) return

        addItemToCart(itemToRevoke).then(() => setConfirm(false))
    }

    return (
        <>
            <Button color="#d0021b" onClick={() => setConfirm(true)}>
                {t('Common_Revoke')}
            </Button>
            <WarningModal
                title={t('Common_Confirm')}
                description={t('Common_ConfirmationBeforeRevocation')}
                yesLabel={t('Common_Yes')}
                noLabel={t('Common_No')}
                open={showConfirm}
                cancel={() => setConfirm(false)}
                proceeding={isAdding}
                proceed={handleRevoke}
            />
        </>
    )
}
const RenewButton = ({ resourceType, ci, cart }) => {
    const isBusinessRole = resourceType === 'BusinessRoles'
    const [enableApiCall, setEnableApiCall] = React.useState(false)
    const [disableButton, setDisableButton] = React.useState(false)

    const { isLoading, data: renewableData } = useRenewableAssignmentInfo(
        ci.assignment?.personId,
        isBusinessRole
            ? ci.assignment?.resourceAssignment?.resourceAssignmentId
            : ci.assignment?.resourceAssignment?.resourceId,
        isBusinessRole,
        enableApiCall,
    )
    const { showSuccessMessage, showWarningMessage } = useNotification()
    useEffect(() => {
        if (disableButton) {
            setEnableApiCall(false)
        }
        setDisableButton(false)
        isItemAlreadyInCart()
        return () => {}
    })
    useEffect(() => {
        if (enableApiCall && renewableData && renewableData.isRenewable) {
            addToCartForRenew()
        } else if (
            enableApiCall &&
            renewableData &&
            renewableData.isRenewable === false
        ) {
            setDisableButton(true)
            showWarningMessage(t('Common_UnableToRenewMessage'))
        }
        return () => {}
    }, [isLoading, renewableData, enableApiCall])
    const isTimeConstrained =
        ci.assignment.assignmentDetails.timeConstraintActive
    const requestPolicyId = ci.resource.requestPolicyId
    const locationId = ci.assignment.resourceAssignment.locationId
        ? ci.assignment.resourceAssignment.locationId
        : ''

    const accessRequestPolicy = useAccessRequestPolicy(
        requestPolicyId,
        isBusinessRole ? ci.resource.id : '',
        isBusinessRole ? locationId : '',
    )

    const { t } = useTranslation()
    const [targetPerson] = useTargetPerson()

    const [addItemToCart, { isLoading: isAdding }] = useAddItemToCart()
    const handleRenewal = () => {
        setEnableApiCall(true)
    }
    const addToCartForRenew = () => {
        let maxEndDate
        if (accessRequestPolicy && accessRequestPolicy.maximumValueInMinutes) {
            maxEndDate = moment()
                .clone()
                .add(accessRequestPolicy.maximumValueInMinutes, 'minutes')
        } else if (
            isTimeConstrained &&
            ci.assignment.assignmentDetails.startDateUtc &&
            ci.assignment.assignmentDetails.endDateUtc
        ) {
            {
                const diff = Math.abs(
                    new Date(ci.assignment.assignmentDetails.startDateUtc) -
                        new Date(ci.assignment.assignmentDetails.endDateUtc),
                )
                const minutes = Math.floor(diff / 1000 / 60)
                maxEndDate = moment().clone().add(minutes, 'minutes')
            }
        }
        const timeConstraintDetails = {
            timeConstrained: isTimeConstrained,
            startDateUtc: moment().utc(),
            endDateUtc: maxEndDate.utc(),
            timebased: isTimeConstrained,
        }

        const itemToRenew = cartHelpers.accessItemToCartItem(
            resourceType !== 'AzureRoles'
                ? resourceType
                : ci.resource.roleGroupType === 'Rbac'
                ? 'AzureRbacRoles'
                : 'AzureAdminRoles',
            targetPerson,
            ci,
            'Add',
            timeConstraintDetails,
        )
        if (!itemToRenew) return

        addItemToCart(itemToRenew).then(setDisableButton(true))
    }
    const isItemAlreadyInCart = () => {
        if (cart && cart.length > 0) {
            const targetPersonCart = cart.filter(
                (el) => el.targetPersonId === ci.assignment?.personId,
            )
            if (targetPersonCart && targetPersonCart.length > 0) {
                const item = targetPersonCart[0].cartItems.filter(
                    (el) =>
                        el.requestableResourceId === ci.resource?.id &&
                        el.locationId ===
                            ci.assignment.resourceAssignment.locationId,
                )

                if (item && item.length > 0) {
                    setDisableButton(true)
                }
            }
        }
    }
    return (
        <>
            <Button
                fill={!disableButton ? '#307fc1' : '#d0d9e2'}
                isFilled
                color={!disableButton ? '#307fc1' : '#d0d9e2'}
                onClick={!disableButton ? handleRenewal : null}
                isDisabled={disableButton}
            >
                {' '}
                <span>{t('Common_Renew')}</span>
                <Icon
                    name="ArrowSmall"
                    fill="white"
                    color="white"
                    direction="right"
                    style={{ marginLeft: '5px' }}
                />
            </Button>
        </>
    )
}

const renderComponent = (attribute, data) => {
    const DynamicComponentToRender = DynamicComponents[attribute.component.name]

    return <DynamicComponentToRender attribute={attribute} data={data} />
}

const TableView = ({
    data,
    attributes,
    onSort,
    loading,
    fallbackMessage,
    onItemClick,
    currentResourceType,
    sortBy,
    sortOrder,
}) => {
    const { t } = useTranslation()
    const { data: allCarts, isFetching } = useAllCarts()

    const toolTipValue = (items) => {
        const newItems = []
        items.map((item) => newItems.push(item.friendlyName))
        return newItems.join(`, `)
    }

    const headings = attributes
        .filter((rt) => !rt.hideInManageAccess)
        .map((a) => ({
            ...a,
            label: t(a.label),
            sort: a.sortable ? a.sortable : false,
        }))

    const handleSort = (data) => {
        const sortingProperty =
            data.manageAccessColumnName &&
            data.manageAccessColumnName.length > 0
                ? data.manageAccessColumnName
                : data.name
        onSort(
            data,
            sortBy !== sortingProperty
                ? 'desc'
                : !sortOrder || sortOrder === '' || sortOrder === 'asc'
                ? 'desc'
                : 'asc',
        )
    }

    const classes = useStyles({})

    const showCredentialActions = useRouteMatch({
        path: '/manageAccess/credentials',
    })
    const isResourceExpired = (endDateUtc) => {
        if (!endDateUtc || endDateUtc.length <= 0) {
            return false
        }
        const today = moment().utc()
        const endDate = moment(endDateUtc).utc()
        return endDate.isBefore(today)
    }
    return (
        <>
            <Table id="identities_list_table" className={classes.table}>
                <TableHead>
                    <TableRow className={classes.tableRow}>
                        {headings.map((heading, index) => {
                            const sortingProperty =
                                heading.manageAccessColumnName &&
                                heading.manageAccessColumnName.length > 0
                                    ? heading.manageAccessColumnName
                                    : heading.name
                            return (
                                <TableCell
                                    key={'pd-head-cell-' + index}
                                    className={clsx(
                                        classes.tableHeaderCell,
                                        classes.tableCell,
                                        classes.headGrey,
                                    )}
                                    align={heading.align}
                                >
                                    {!heading.sort ? (
                                        <span style={{ display: 'flex' }}>
                                            {heading.label}
                                        </span>
                                    ) : (
                                        <span style={{ display: 'flex' }}>
                                            <Tooltip
                                                title={
                                                    sortBy ===
                                                        sortingProperty &&
                                                    sortOrder === 'desc'
                                                        ? t(
                                                              'Common_SortAscending',
                                                          )
                                                        : t(
                                                              'Common_SortDescending',
                                                          )
                                                }
                                            >
                                                <TableSortLabel
                                                    active={heading.sort}
                                                    direction={
                                                        sortBy ===
                                                        sortingProperty
                                                            ? sortOrder
                                                            : 'asc'
                                                    }
                                                    className={
                                                        sortBy !==
                                                        sortingProperty
                                                            ? classes.tableSortLabel
                                                            : clsx(
                                                                  classes.tableSortLabel,
                                                                  classes.activeTableSortLabel,
                                                              )
                                                    }
                                                    style={{
                                                        color:
                                                            sortBy ===
                                                                sortingProperty &&
                                                            'yellow !important',
                                                    }}
                                                    onClick={() =>
                                                        handleSort(heading)
                                                    }
                                                >
                                                    {heading.label}
                                                </TableSortLabel>
                                            </Tooltip>
                                        </span>
                                    )}
                                </TableCell>
                            )
                        })}
                        <TableCell
                            className={clsx(
                                classes.tableHeaderCell,
                                classes.tableCell,
                                classes.headGrey,
                            )}
                        ></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {loading && (
                        <TableRow>
                            <TableCell
                                colSpan={headings.length + 1}
                                style={{ textAlign: 'center' }}
                            >
                                <Loader />
                            </TableCell>
                        </TableRow>
                    )}
                    {data &&
                        (data.length === 0 ? (
                            <TableRow className={classes.tableRow}>
                                <TableCell
                                    colSpan={headings.length + 1}
                                    style={{ textAlign: 'center' }}
                                >
                                    {fallbackMessage}
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {data.map((ci, index) => {
                                    const isRevokable =
                                        ci.assignment?.assignmentDetails
                                            ?.isRevokable
                                    const isRenewable = isResourceExpired(
                                        ci.assignment?.assignmentDetails
                                            ?.endDateUtc,
                                    )

                                    return (
                                        <TableRow
                                            key={`${getUniqueId(ci)}-${index} `}
                                            className={classes.tableRow}
                                        >
                                            {attributes
                                                .filter(
                                                    (rt) =>
                                                        !rt.hideInManageAccess,
                                                )
                                                .map((a) => {
                                                    let wrappedCellContent

                                                    if (a.component) {
                                                        wrappedCellContent = renderComponent(
                                                            a,
                                                            ci,
                                                        )
                                                    } else if (
                                                        a.type !== 'array'
                                                    ) {
                                                        const cellContent = (
                                                            <Box
                                                                maxHeight="200px"
                                                                display="block"
                                                                maxWidth="200px"
                                                                overflow="auto"
                                                                style={{
                                                                    display:
                                                                        'block',
                                                                    wordBreak:
                                                                        'break-word',
                                                                    wordWrap:
                                                                        'break-word',
                                                                }}
                                                            >
                                                                {getValue(ci, a)
                                                                    .length >
                                                                maxCharacters
                                                                    ? `${getValue(
                                                                          ci,
                                                                          a,
                                                                      ).substring(
                                                                          0,
                                                                          maxCharacters,
                                                                      )}...`
                                                                    : getValue(
                                                                          ci,
                                                                          a,
                                                                      )}
                                                            </Box>
                                                        )
                                                        wrappedCellContent =
                                                            getValue(ci, a)
                                                                .length >
                                                            maxCharacters ? (
                                                                <Tooltip
                                                                    title={getValue(
                                                                        ci,
                                                                        a,
                                                                    )}
                                                                    color="#5D6870"
                                                                    fontColor="#ffffff"
                                                                    enterDelay={
                                                                        500
                                                                    }
                                                                    enterNextDelay={
                                                                        500
                                                                    }
                                                                    interactive={
                                                                        true
                                                                    }
                                                                    tooltipStyles={
                                                                        tooltipStyles
                                                                    }
                                                                >
                                                                    {
                                                                        cellContent
                                                                    }
                                                                </Tooltip>
                                                            ) : (
                                                                cellContent
                                                            )
                                                    } else {
                                                        wrappedCellContent = (
                                                            <TextFormatter
                                                                style={{
                                                                    textDecoration:
                                                                        'underline',
                                                                }}
                                                                value={toolTipValue(
                                                                    getValue(
                                                                        ci,
                                                                        a,
                                                                    ),
                                                                )}
                                                            />
                                                        )
                                                    }
                                                    return (
                                                        <TableCell
                                                            key={`${ci.resource.id}${a.name}`}
                                                            className={
                                                                classes.tableCell
                                                            }
                                                        >
                                                            {wrappedCellContent}
                                                        </TableCell>
                                                    )
                                                })}

                                            <TableCell
                                                className={
                                                    classes.tableActionCell
                                                }
                                                style={{
                                                    display: 'flex',

                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                <Button
                                                    color="#56BEAC"
                                                    onClick={() => {
                                                        onItemClick(ci)
                                                    }}
                                                >
                                                    {t('ManageAccess_Details')}
                                                </Button>
                                                {isRevokable &&
                                                    !isRenewable && (
                                                        <RevokeButton
                                                            resourceType={
                                                                currentResourceType.name
                                                            }
                                                            ci={ci}
                                                        />
                                                    )}
                                                {isRenewable && (
                                                    <RenewButton
                                                        resourceType={
                                                            currentResourceType.name
                                                        }
                                                        cart={allCarts}
                                                        ci={ci}
                                                    />
                                                )}

                                                {showCredentialActions && (
                                                    <CredentialsActionsButton
                                                        item={ci.resource}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </>
                        ))}
                </TableBody>
            </Table>
        </>
    )
}

export default TableView
