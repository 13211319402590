import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'
import { Box, makeStyles, createStyles } from '@material-ui/core'
import ApplicationAccessLevel from './ApplicationAccessLevel'
import { ApplicationOverView } from './ApplicationOverView'
import { ApplicationAdditionalInformation } from './AdditionalDetails'
import { ApplicationActions } from './ApplicationActions'
import Button from './Button'
import { Icon } from 'packages/eid-icons'
import { useAddItemToCart, useTargetPerson } from 'hooks'
import cartHelpers from 'containers/Cart/cartHelpers'
import { WarningModal } from 'packages/eid-ui'
const useStyles = makeStyles((theme) =>
    createStyles({
        revokeButton: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            miHeight: '156px',
            justifyContent: 'space-between',
        },
    }),
)
const ApplicationDetails = ({
    application,
    accessLevel,
    accessLevels,
    setAccessLevelValue,
    targetPerson,
    isCheckingAccess,
    applicationId,
    isManageAccess,
    item,
    currentResourceType,
}) => {
    const { t } = useTranslation()

    const [activeTab, setActiveTab] = useState('overview')
    return (
        <Fragment>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
                <ItemDetails.Tabs.Tab
                    value="action"
                    label={t('Common_Actions')}
                />
            </ItemDetails.Tabs>
            {activeTab === 'overview' && (
                <ApplicationOverView
                    item={item}
                    isManageAccess={isManageAccess}
                    data={application}
                />
            )}
            {activeTab === 'additionalInformation' && (
                <ApplicationAdditionalInformation
                    isAzureApplication={application.isAzureApplication}
                    applicationId={applicationId}
                />
            )}
            {activeTab === 'action' && (
                <ApplicationActions data={application} />
            )}
            {!isManageAccess && application && accessLevels?.length > 0 && (
                <Box minWidth={'100%'}>
                    <ApplicationAccessLevel
                        accessLevel={accessLevel}
                        accessLevels={accessLevels}
                        setAccessLevelValue={(val) => setAccessLevelValue(val)}
                        isCheckingAccess={isCheckingAccess}
                        application={application}
                        targetPerson={targetPerson}
                    />
                </Box>
            )}
            {isManageAccess &&
                currentResourceType &&
                item.assignment.assignmentDetails.isRevokable && (
                    <RevokeButton
                        resourceType={currentResourceType.name}
                        ci={item}
                    />
                )}
        </Fragment>
    )
}

const RevokeButton = ({ resourceType, ci }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [showConfirm, setConfirm] = useState(false)
    const [targetPerson] = useTargetPerson()

    const [addItemToCart, { isLoading: isAdding }] = useAddItemToCart()

    const handleRevoke = () => {
        const itemToRevoke = cartHelpers.accessItemToCartItem(
            resourceType,
            targetPerson,
            ci,
            'Remove',
        )
        if (!itemToRevoke) return

        addItemToCart(itemToRevoke).then(() => setConfirm(false))
    }

    return (
        <>
            <div className={classes.revokeButton}>
                <Button color="#ff4d5f" onClick={() => setConfirm(true)}>
                    {t('Common_Revoke')}
                    <Icon name="Close" />
                </Button>
            </div>
            <WarningModal
                title={t('Common_Confirm')}
                description={t('Common_ConfirmationBeforeRevocation')}
                yesLabel={t('Common_Yes')}
                noLabel={t('Common_No')}
                open={showConfirm}
                cancel={() => setConfirm(false)}
                proceeding={isAdding}
                proceed={handleRevoke}
            />
        </>
    )
}

export default ApplicationDetails
