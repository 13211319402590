import React, { useState } from 'react'
import { FunctionsList, ItemDetails, StyledSearchInput } from 'components'
import { useDebounce, useIsSmallScreen } from 'packages/core'
import { Box, styled, Typography } from '@material-ui/core'
import { Pagination } from 'packages/eid-ui'
import {
    useAzureRoleGlobalSensitiveFunctions,
    useAzureRoleBr,
    useTargetPerson,
} from 'hooks'
import { useTranslation } from 'react-i18next'
import cartHelpers from 'containers/Cart/cartHelpers'
import { CommonAttributes } from '../CommonAttributes'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { RenewRevoke } from 'components/RenewRevoke'
import { Skeleton } from '@material-ui/lab'

const PaddedDiv = styled('div')({
    padding: '0 31px',
})

const GlobalSensitiveFunctions = ({ azureAdminRole }) => {
    const take = 10
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()

    const [page, setPage] = React.useState(1)
    const [searchKey, setSearchKey] = useState('')

    const handlePageChange = (_, value) => {
        setPage(value)
    }

    const handleSearchChange = (event) => {
        setPage(1)
        const keyword = event.target.value
        setSearchKey(keyword)
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const { latestData } = useAzureRoleGlobalSensitiveFunctions(
        azureAdminRole.id,
        (page - 1) * take,
        take,
        null,
        debouncedSearchValue && encodeURIComponent(debouncedSearchValue),
    )

    const globalSensitiveFunctions = latestData ? latestData.data : undefined

    const globalFunctionsLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / take)
        : 0

    return (
        <>
            <PaddedDiv
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '16px 31px',
                }}
            >
                <Box width="50%">
                    <Typography
                        style={{
                            fontSize: '12px',
                            lineHeight: 1.33,
                            color: '#9b9b9b',
                            wordBreak: 'break-word',
                            textTransform: 'uppercase',
                            minWidth: '80px',
                        }}
                    >
                        {t('Common_GlobalSensitiveFunctions')}
                    </Typography>
                </Box>

                <Box width={!isSmallScreen ? '308px' : '50%'} minWidth="130px">
                    <StyledSearchInput
                        fullWidth
                        type="text"
                        placeholder={t('Common_Search')}
                        value={searchKey}
                        onChange={handleSearchChange}
                    />
                </Box>
            </PaddedDiv>

            {globalFunctionsLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <>
                    {globalSensitiveFunctions && (
                        <>
                            <Box overflow="auto">
                                <FunctionsList
                                    data={globalSensitiveFunctions}
                                    loading={globalFunctionsLoading}
                                />

                                {numberOfPages > 1 && (
                                    <Box
                                        padding={
                                            !isSmallScreen
                                                ? '20px 32px'
                                                : '16px'
                                        }
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={
                                            !isSmallScreen
                                                ? 'flex-start'
                                                : 'center'
                                        }
                                    >
                                        <Pagination
                                            count={numberOfPages}
                                            size={
                                                !isSmallScreen
                                                    ? 'large'
                                                    : 'small'
                                            }
                                            page={page}
                                            onChange={handlePageChange}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </>
                    )}
                </>
            )}
        </>
    )
}

const OverviewSection = ({ item }) => {
    const { t } = useTranslation()

    const azureAdminRole = item.resource

    const [targetPerson] = useTargetPerson()

    const { data: businessRequestItem, isLoading } = useAzureRoleBr(
        targetPerson.id,
        item.assignment.resourceAssignment.resourceId,
        item.assignment.resourceAssignment.resourceTypeId,
    )

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer bottomPadding="0">
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_SystemType')}
                    value={azureAdminRole.resourceSystemTypeFriendlyName}
                    orientation={attributeOrientation}
                />
                <ItemDetails.Attribute
                    label={t('Common_RoleType')}
                    value={azureAdminRole.roleTypeFriendlyName}
                    orientation={attributeOrientation}
                />
                {azureAdminRole.resourceSystemFriendlyName && (
                    <ItemDetails.Attribute
                        label={t('Common_Tenant')}
                        value={azureAdminRole.resourceSystemFriendlyName}
                        orientation={attributeOrientation}
                    />
                )}
                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={azureAdminRole.description}
                    orientation={attributeOrientation}
                />
                {isLoading && <Skeleton height={32} />}
                <CommonAttributes
                    item={item}
                    businessRequestItem={businessRequestItem}
                />
            </PaddedDiv>

            <GlobalSensitiveFunctions azureAdminRole={azureAdminRole} />
        </ItemDetails.AttributesContainer>
    )
}

const AzureAdminRoleDetails = ({ item, toggleDrawer }) => {
    const { t } = useTranslation()

    const registry = useRegistry()

    const azureAdminRoleType = registry.get(
        ResourceTypesNamespace,
        'AzureAdminRoles',
    )

    const [activeTab, setActiveTab] = useState('overview')

    const [targetPerson] = useTargetPerson()

    const preRevoke = () => {
        const itemToAdd = cartHelpers.azAdminRoleToCartItem({
            targetPerson,
            assignmentType: 'Remove',
            azureAdminRole: item.resource,
            assignmentStatus: item.assignment,
        })
        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const preRenew = (baseObj) => {
        const itemToRenew = cartHelpers.azAdminRoleToCartItem({
            targetPerson,
            assignmentType: 'Add',
            azureAdminRole: item.resource,
            assignmentStatus: item.assignment,
            ...baseObj,
        })
        if (!itemToRenew) return undefined

        return itemToRenew
    }

    return (
        <>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
            </ItemDetails.Tabs>

            {activeTab === 'overview' && <OverviewSection item={item} />}

            <RenewRevoke
                resourceType={azureAdminRoleType}
                resource={item.resource}
                assignment={item.assignment}
                preRenew={preRenew}
                postRenew={toggleDrawer}
                preRevoke={preRevoke}
                postRevoke={toggleDrawer}
            />
        </>
    )
}

export default AzureAdminRoleDetails
