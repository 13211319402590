import { default as EndDateManageAccess } from './EndDateManageAccess'
import { default as ConnectSwitch } from './ConnectSwitch'
import { default as CredentialsLockedAttribute } from './CredentialsLockedAttribute'
import { default as CredentialStatusButton } from './CredentialStatusButton'
import { default as CredentialRemainingTime } from './CredentialRemainingTime'
import { default as DateTimeGenericComponent } from './DateTimeGenericComponent'

const DynamicComponents = {
    EndDateManageAccess,
    ConnectSwitch,
    CredentialsLockedAttribute,
    CredentialStatusButton,
    DateTimeGenericComponent,
    CredentialRemainingTime,
}

export default DynamicComponents
