import {
    useApiGetWithInfiniteScroll,
    isNilOrEmpty,
    useGetControlsAccess,
} from 'packages/core'
import { useTargetPerson } from 'hooks'
import { useResourceTypeContext } from 'resourceTypeContext'
import useAppliedFilterValues from 'components/AppliedFilters/useAppliedFilterValues'
import config from 'config'

const getValue = (filter, contextValue) => {
    switch (filter.type) {
        case 'checkbox':
        case 'nullableBoolean':
        case 'text':
            if (filter.contextProp.startsWith('advancedSearch')) {
                return encodeURIComponent(
                    contextValue
                        .toString()
                        .split(',')
                        .map((item) => item.trim())
                        .join(' '),
                )
            } else return encodeURIComponent(contextValue)
        case 'tree':
        case 'autocomplete':
        default:
            return encodeURIComponent(
                contextValue[filter.queryParam.valueProp]
                    .toString()
                    .split(',')
                    .map((item) => item.trim())
                    .join(' '),
            )
    }
}

const buildQueryParams = (state, appliedFilterValues, currentResourceType) => {
    const queryParams = []
    const advancedSearchParamNames = []
    const advancedSearchParamTypes = []
    const advancedSearchParamValues = []

    const tags = appliedFilterValues.filter((f) => f.category === 'tags')

    if (tags.length > 0) {
        const commaSeparatedTagValues = tags.map((t) => t.label).join(',')
        queryParams.push(`tags=${commaSeparatedTagValues}`)
    }

    const checklistFilters = appliedFilterValues.filter(
        (f) => f.category === 'checklist',
    )

    if (checklistFilters.length > 0) {
        const contextProp = checklistFilters[0].contextProp
        const queryParam = checklistFilters[0].queryParam

        const checklist = []
        state[contextProp].forEach((i) => {
            checklist.push(i[queryParam.valueProp])
        })

        const commaSeparatedValues = checklist.join(',')
        queryParams.push(`${[queryParam.name]}=${commaSeparatedValues}`)
    }

    const nonTags = appliedFilterValues.filter(
        (f) => f.category !== 'tags' && f.category !== 'checklist',
    )

    nonTags.map((appliedFilter) => {
        if (appliedFilter.category === 'generic') {
            const filter = currentResourceType.filters.find(
                (f) => f.name === appliedFilter.name,
            )
            if (filter) {
                // if type prop is there, it's an advanced search.
                if (filter.queryParam.type) {
                    advancedSearchParamNames.push(filter.queryParam.name)
                    advancedSearchParamTypes.push(filter.queryParam.type)
                    advancedSearchParamValues.push(
                        getValue(filter, state[filter.contextProp]),
                    )
                } else {
                    queryParams.push(
                        `${filter.queryParam.name}=${getValue(
                            filter,
                            state[filter.contextProp],
                        )}`,
                    )
                }
            }
        } else if (appliedFilter.category === 'forms') {
            var advancedSearchFilter = currentResourceType.filters.find(
                (f) => f.type === 'advancedSearch',
            )
            if (
                advancedSearchFilter &&
                !isNilOrEmpty(advancedSearchFilter.forms)
            ) {
                const formsFilter = advancedSearchFilter.forms.filters.find(
                    (f) => f.name === appliedFilter.name,
                )
                if (formsFilter) {
                    if (formsFilter.queryParam.type) {
                        advancedSearchParamNames.push(
                            formsFilter.queryParam.name,
                        )
                        advancedSearchParamTypes.push(
                            formsFilter.queryParam.type,
                        )
                        advancedSearchParamValues.push(
                            getValue(
                                formsFilter,
                                state[formsFilter.contextProp],
                            ),
                        )
                    } else {
                        queryParams.push(
                            `${formsFilter.queryParam.name}=${getValue(
                                formsFilter,
                                state[formsFilter.contextProp],
                            )}`,
                        )
                    }
                }
            }
        }
    })

    if (advancedSearchParamNames.length > 0) {
        queryParams.push(
            `advancedSearchParamNames=${advancedSearchParamNames.join(',')}`,
        )
        queryParams.push(
            `advancedSearchParamValues=${advancedSearchParamValues.join(',')}`,
        )
        queryParams.push(
            `advancedSearchParamTypes=${advancedSearchParamTypes.join(',')}`,
        )
    }

    return queryParams
}

const useManageAccessResourcesOld = (currentResourceType, enabled = true) => {
    const url = `${currentResourceType.dataSource.url}/allAssignedResources`

    const [targetPerson] = useTargetPerson()
    const [
        {
            sorting: sortQuery,
            searchTerm,
            manageAccess: {
                showTimeConstrained,
                showDirectAssignedOnly,
                accountStore,
                accountStoreUsageType,
                roleGroupType,
                showExpiringAccess,
            },
        },
    ] = useResourceTypeContext()
    const qs = []

    qs.push(`targetPersonId=${targetPerson.id}`)

    if (roleGroupType) {
        qs.push(`roleGroupType=${roleGroupType.id}`)
    }

    if (showTimeConstrained) {
        qs.push(`showTimeConstrained=${showTimeConstrained}`)
    }
    if (showExpiringAccess) {
        qs.push(`showExpiringAccess=${showExpiringAccess}`)
    }

    if (showDirectAssignedOnly) {
        qs.push(`showDirectAssignedOnly=${showDirectAssignedOnly}`)
    }

    if (searchTerm) {
        qs.push(`searchTerm=${encodeURIComponent(searchTerm)}`)
    }

    if (accountStoreUsageType) {
        qs.push(`accountStoreUsageTypeId=${accountStoreUsageType.id}`)
    }
    if (accountStore) {
        qs.push(`accountStoreId=${accountStore.id}`)
    }

    if (sortQuery.sortOrder) {
        qs.push(
            `sortBy=${sortQuery.sortBy}&desc=${
                sortQuery.sortOrder === 'desc' && true
            }`,
        )
    }

    const queryString = qs.join('&')

    return useApiGetWithInfiniteScroll(url, queryString, enabled)
}

const useResourceTypeApi = (currentResourceType) => {
    const controls = useGetControlsAccess()

    const [targetPerson] = useTargetPerson()
    const [state] = useResourceTypeContext()

    const appliedFiltersValues = useAppliedFilterValues(currentResourceType)

    const queryParams = buildQueryParams(
        state,
        appliedFiltersValues,
        currentResourceType,
    )

    if (targetPerson) {
        queryParams.push(`targetPersonId=${targetPerson.id}`)
    }

    if (state.searchTerm) {
        queryParams.push(`searchTerm=${encodeURIComponent(state.searchTerm)}`)
    }

    if (state.accountStoreUsageType) {
        queryParams.push(
            `accountStoreUsageTypeId=${state.accountStoreUsageType.id}`,
        )
    }

    if (state.accountStore) {
        queryParams.push(`accountStoreId=${state.accountStore.id}`)
    }

    if (state.shouldLoadTags) {
        queryParams.push('includeTagsResponse=true')
    }

    if (currentResourceType.name === 'ApplicationRoles') {
        const hasAccessToSuggestedApplicationRolesControl =
            controls.findIndex(
                (c) => c.name === 'ITShop-SuggestedApplicationRoles-Control',
            ) >= 0

        if (
            !hasAccessToSuggestedApplicationRolesControl &&
            config &&
            config.FETCH_SUGGESTED_APPLICATION_ROLES_ALWAYS
        ) {
            queryParams.push('showSuggested=true')
        }
    }

    return queryParams.join('&')
}

const useManageAccessResources = (currentResourceType, enabled = true) => {
    const [{ sorting: sortQuery }] = useResourceTypeContext()

    let queryString = useResourceTypeApi(currentResourceType)

    if (sortQuery.sortOrder) {
        queryString = `${queryString}&sortBy=${sortQuery.sortBy}&desc=${
            sortQuery.sortOrder === 'desc' && true
        }`
    }

    return useApiGetWithInfiniteScroll(
        `${currentResourceType.dataSource.url}/allAssignedResources`,
        queryString,
        enabled,
    )
}

export const useManageAccessHistoryResources = (
    currentResourceType,
    enabled = true,
) => {
    const [{ sorting: sortQuery }] = useResourceTypeContext()

    let queryString = useResourceTypeApi(currentResourceType)

    if (sortQuery.sortOrder) {
        queryString = `${queryString}&sortBy=${sortQuery.sortBy}&desc=${
            sortQuery.sortOrder === 'desc' && true
        }`
    }

    return useApiGetWithInfiniteScroll(
        `${currentResourceType.dataSource.url}/history`,
        queryString,
        enabled,
    )
}

export default useManageAccessResources
