import React, { FC, Fragment } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'
import { useIsSmallScreen } from 'packages/core'
import {
    BusinessRequestItemDetails,
    BusinessRequestNameAttribute,
} from './BusinessRequestNameAttribute'
import useSubcomponents from 'useSubcomponents'
import { AccessRequestPolicyDetails } from 'components/AccessRequestPolicyDetails'
import { useAccessRequestPolicy } from 'hooks'
import { Divider } from 'packages/eid-ui'

export interface ICommonAttributesProps {
    item: any
    businessRequestItem?: BusinessRequestItemDetails
}

export const CommonAttributes: FC<ICommonAttributesProps> = (props) => {
    const { t } = useTranslation()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const {
        canSeeBusinessRequest,
        canSeeAccessRequestPolicy,
    } = useSubcomponents()

    const isBusinessRole =
        props.item.resource.resourceTypeName === 'BusinessRole'

    const accessRequestPolicy = useAccessRequestPolicy(
        props.item.resource.requestPolicyId,
        isBusinessRole ? props.item.resource.id : '',
        isBusinessRole
            ? props.item.assignment.resourceAssignment.locationId
            : '',
    )

    return (
        <Fragment>
            {props.item.assignment.assignmentDetails.timeConstraintActive && (
                <Fragment>
                    <ItemDetails.Attribute
                        label={t('Common_StartDateTime')}
                        value={moment(
                            props.item.assignment.assignmentDetails
                                .startDateUtc,
                        )
                            .clone()
                            .local()
                            .format('LLL')}
                        orientation={attributeOrientation}
                    />
                    <ItemDetails.Attribute
                        label={t('Common_EndDateTime')}
                        value={moment(
                            props.item.assignment.assignmentDetails.endDateUtc,
                        )
                            .clone()
                            .local()
                            .format('LLL')}
                        orientation={attributeOrientation}
                    />
                </Fragment>
            )}
            {canSeeBusinessRequest && props.businessRequestItem && (
                <BusinessRequestNameAttribute
                    businessRequestItem={props.businessRequestItem}
                />
            )}
            {canSeeAccessRequestPolicy && accessRequestPolicy && (
                <Fragment>
                    <Divider />
                    <AccessRequestPolicyDetails policy={accessRequestPolicy} />
                </Fragment>
            )}
        </Fragment>
    )
}
