import { FC, useState } from 'react'
import {
    makeStyles,
    createStyles,
    Theme,
    InputBase,
    Paper,
    InputBaseProps,
} from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { IconButton } from 'packages/eid-ui'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '8px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 400,
            border: 'solid 1px #d8d8dd',
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
            fontSize: '18px',
            color: '#8b909a',
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
    }),
)

export interface PasswordInputProps extends InputBaseProps {}

export const PasswordInput: FC<PasswordInputProps> = (props) => {
    const classes = useStyles()

    const { ...rest } = props
    const [showPassword, setShow]: any = useState(false)

    return (
        <Paper className={classes.root}>
            <IconButton className={classes.iconButton}>
                <Icon name="Lock" color="#D2D2D2" />
            </IconButton>
            <InputBase
                className={classes.input}
                placeholder="enter password"
                type={showPassword ? 'text' : 'password'}
                {...rest}
            />
            <IconButton
                className={classes.iconButton}
                onClick={() => setShow((prev: any) => !prev)}
            >
                <Icon name="View" color="#d2d2d2" />
            </IconButton>
        </Paper>
    )
}
