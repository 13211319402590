import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useCheckInCredential } from 'hooks'
import { EidButton } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'

const CheckIn = ({ data, drawerView }) => {
    const item = data?.resource

    const { t } = useTranslation()
    const [
        checkInCredential,
        { isLoading: isCheckingIn },
    ] = useCheckInCredential(item.id)

    const handleCheckIn = () => {
        checkInCredential(item.checkOutId)
    }

    return (
        <>
            {!drawerView ? (
                <Box style={{ height: '24px' }}>
                    <EidButton.CheckInListing
                        loading={isCheckingIn}
                        label={t('CheckIn')}
                        disabled={!item.canCheckIn}
                        onClick={handleCheckIn}
                    />
                </Box>
            ) : (
                <Box minWidth="167px">
                    <EidButton.DrawerActionButton
                        fontColor="#ffffff"
                        bgColor="#307fc1"
                        width="100%"
                        disabled={!item.isCheckOut}
                        onClick={handleCheckIn}
                        loading={isCheckingIn}
                    >
                        <Box display="flex" alignItems="center">
                            <Icon
                                name="CheckOut"
                                color="#ffffff"
                                direction={'right'}
                            />
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            marginLeft="10px"
                        >
                            <Typography style={{ color: '#ffffff' }}>
                                {t('CheckIn')}
                            </Typography>
                        </Box>
                    </EidButton.DrawerActionButton>
                </Box>
            )}
        </>
    )
}

CheckIn.defaultProps = {
    drawerView: false,
}

export default CheckIn
