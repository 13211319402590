import { FC, Fragment } from 'react'
import { Box, createStyles } from '@material-ui/core'
import { ToggleSwitch } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { StartDateControl } from './StartDateControl'
import { EndDateControl } from './EndDateControl'
import { makeStyles } from '@material-ui/core/styles'

export interface TimeConstrainedControlProps {
    selectedStartDate: any
    setSelectedStartDate: any
    selectedEndDate: any
    setSelectedEndDate: any
    isEndDateDisabled?: any
    maxEndDate?: any
    hasInvalidDates: any
    validationMessage: any
    toggle?: boolean
    setToggle?: any
    disabled: boolean
    isApplication?: boolean
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {},
        applicationStartDatePickerContainer: {
            width: '157px',
            marginBottom:'25px',

            '@media (max-width:959px)': {
                width: '100%',
                marginLeft: '0px',
                paddingTop: '8px',
                paddingBottom: '8px',
            },
        },
        startDatePickerContainer: {
            marginLeft: '16px',
            width: '157px',

            '@media (max-width:959px)': {
                width: '100%',
                marginLeft: '0px',
                paddingTop: '8px',
                paddingBottom: '8px',
            },
        },
        applicationEndDatePickerContainer: {
            width: '157px',

            '@media (max-width:959px)': {
                width: '100%',
                marginLeft: '0px',
                paddingTop: '8px',
                paddingBottom: '8px',
            },
        },
        endDatePickerContainer: {
            marginLeft: '24px',
            width: '157px',

            '@media (max-width:959px)': {
                width: '100%',
                marginLeft: '0px',
                paddingTop: '8px',
                paddingBottom: '8px',
            },
        },
        policyViolationMessage: {
            position: 'absolute',
            fontSize: '12px',
            color: theme.palette.error.main,
            marginRight: '12px',
        },
        defaultPicker: {
            display:'flex',
        },
        applicationPicker: {
            marginLeft:'20px',
        },
    }),
)

export const TimeConstrainedControl: FC<TimeConstrainedControlProps> = ({
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    isEndDateDisabled,
    maxEndDate,
    hasInvalidDates,
    validationMessage,
    toggle,
    disabled,
    setToggle,
    isApplication,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <>
            {!disabled && (
                <Box>
                    <ToggleSwitch
                        value={toggle}
                        label={t('Common_SetDuration')}
                        labelColor="#5d6870"
                        disabled={disabled}
                        onChange={() => setToggle((prev: any) => !prev)}
                    />
                </Box>
            )}

            {toggle && (
                <Box
                    className={
                        !isApplication
                            ? classes.defaultPicker
                            : classes.applicationPicker
                    }
                >
                    <Fragment>
                        <Box
                            className={
                                !isApplication
                                    ? classes.startDatePickerContainer
                                    : classes.applicationStartDatePickerContainer
                            }
                        >
                            <StartDateControl
                                selectedStartDate={selectedStartDate}
                                setSelectedStartDate={setSelectedStartDate}
                            />
                        </Box>
                        <Box
                            className={
                                !isApplication
                                    ? classes.endDatePickerContainer
                                    : classes.applicationEndDatePickerContainer
                            }
                        >
                            <EndDateControl
                                selectedStartDate={selectedStartDate}
                                selectedEndDate={selectedEndDate}
                                setSelectedEndDate={setSelectedEndDate}
                                maxEndDate={maxEndDate}
                                hasInvalidDates={hasInvalidDates}
                                disabled={isEndDateDisabled}
                            />
                            {hasInvalidDates && validationMessage && (
                                <Box className={classes.policyViolationMessage}>
                                    {validationMessage}
                                </Box>
                            )}
                        </Box>
                    </Fragment>
                </Box>
            )}
        </>
    )
}
