import { useQuery } from 'react-query'
import {
    useApiGetWithInfiniteScroll,
    useAxios,
    useQuery as useBrowserQuery,
} from 'packages/core'
import { useTargetPerson } from 'hooks'
import { useResourceTypeContext } from 'resourceTypeContext'
import { useEffect, useState } from 'react'
import useConfiguration from 'useConfiguration'
import { useHistory } from 'react-router'

export const APPLICATIONS_KEY = 'APPLICATIONS'

export const useApplication = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/ProtectedAppResources/${id}/`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplications = () => {
    const [targetPerson] = useTargetPerson()
    let queryParams = []

    const [state]: any = useResourceTypeContext()

    if (targetPerson) {
        queryParams.push(`targetPersonId=${targetPerson.id}`)
    }
    if (state.searchTerm) {
        queryParams.push(`searchTerm=${encodeURIComponent(state.searchTerm)}`)
    }
    queryParams.push(
        `showOnlyAzureApplications=${state.showOnlyAzureApplications}`,
    )

    let queryString = queryParams.join('&')
    if (state.sorting.sortOrder) {
        queryString = `${queryString}&sortBy=${state.sorting.sortBy}&desc=${
            state.sorting.sortOrder === 'desc' && true
        }`
    }

    let enabled = true

    return useApiGetWithInfiniteScroll(
        '/api/ProtectedAppResources',
        queryString,
        enabled,
    )
}

export const useResourceTypes = (applicationId: string) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'RESOURCE_TYPES', applicationId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/ProtectedAppResources/resourceTypes/${applicationId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(applicationId),
        },
    )
}

export const useHandleSelectApplication = () => {
    const query = useBrowserQuery()
    const history = useHistory()

    const [selectedApplication, setSelectedApplication] = useState<any>(null)

    const selectApplication = (application: any) => {
        setSelectedApplication(application)
    }

    useEffect(() => {
        if (selectedApplication) {
            let route
            query.set('selected', selectedApplication.id)
            route = '/Applications'
            history.push(`${route}?${query.toString()}`)
            setSelectedApplication(null)
        }
    }, [selectedApplication])

    return {
        selectApplication,
    }
}

export const useApplicationOwners = (applicationId: string) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'OWNERS', applicationId],
        () =>
            callApi({
                method: 'GET',
                url: `api/people/resourceOwners?resourceId=${applicationId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(applicationId),
        },
    )
}

export const useAzureApplicationOwnersAndDeputies = (id: string) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'OWNERS_AND_DEPUTIES', id],
        () =>
            callApi({
                method: 'GET',
                url: `api/ProtectedAppResources/ownersDeputies/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useCheckApplicationsAccess = (
    id: string | null,
    targetPersonId: string,
) => {
    const callApi = useAxios()
    return useQuery(
        [`${APPLICATIONS_KEY}_ACCESS`, id, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/ProtectedAppResources/checkAssigmentStatus/${id}/${targetPersonId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id) && Boolean(targetPersonId),
        },
    )
}
