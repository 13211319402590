import { queryCache, useMutation, useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useAuthState, useAxios } from 'packages/core'
import { useNotification } from 'packages/eid-ui'
import { useAppState } from 'appContext'
import { useCurrentPerson } from './personHooks'
import { IRiskFunction } from '../components/Cart/types'

export const CART_KEY = 'CART'

const refreshTargetPersonCart = (targetPersonId: string) => {
    queryCache.invalidateQueries(
        (x) =>
            x.queryKey.includes(CART_KEY) &&
            x.queryKey.includes(targetPersonId) &&
            !x.queryKey.includes('PREVIOUS_EVALUATION') &&
            !x.queryKey.includes('RISK_FUNCTIONS') &&
            !x.queryKey.includes('CART_LINE_MANAGER'),
    )
}

export const removeTargetPersonCartEvaluation = (targetPersonId: string) => {
    const keyFn = (x: any) =>
        x.queryKey.includes(CART_KEY) &&
        x.queryKey.includes(targetPersonId) &&
        x.queryKey.includes('PREVIOUS_EVALUATION')
    const query = queryCache.getQuery(keyFn)
    if (query) {
        queryCache.setQueryData(query.queryKey, undefined)
    }
}

export const refreshAllCarts = () => {
    queryCache.invalidateQueries(
        (c) => c.queryKey.includes(CART_KEY) && c.queryKey.includes('ALL'),
    )
}

export const useTargetPerson = () => {
    const [{ currentUserId }]: any = useAuthState()
    const storageKey = `targetPerson.${currentUserId}:${window.location.origin}`

    const [{ targetPerson }, dispatch]: any = useAppState()

    const setTargetPerson = (targetPerson: any) => {
        sessionStorage.setItem(storageKey, targetPerson.id)
        dispatch({
            type: 'SET_TARGET_PERSON',
            payload: targetPerson,
        })
    }

    const getSavedTargetPersonId = () => sessionStorage.getItem(storageKey)

    const deleteSavedTargetPersonId = () => {
        if (sessionStorage.getItem(storageKey)) {
            sessionStorage.removeItem(storageKey)
        }
    }
    return [
        targetPerson,
        setTargetPerson,
        {
            getSavedTargetPersonId,
            deleteSavedTargetPersonId,
        },
    ]
}

const _useAllCarts = () => {
    const { data: currentPerson } = useCurrentPerson()
    const [targetPerson] = useTargetPerson()
    const callApi = useAxios()

    return useQuery([CART_KEY, 'ALL'], () =>
        callApi({
            method: 'GET',
            url: '/api/cart/all',
        }),
    )
}

export const useAllCarts = () => {
    const { data: currentPerson } = useCurrentPerson()
    const [targetPerson] = useTargetPerson()

    const { data, isFetching } = _useAllCarts()

    if (!data) {
        return { data: undefined, isFetching }
    }

    const activeCarts = data.filter(
        (c: any) =>
            c.targetPersonId === currentPerson.id ||
            c.targetPersonId === targetPerson.id ||
            c.cartItems.length > 0,
    )
    const hasMultipleCarts = activeCarts.length > 1

    return { data: activeCarts, isFetching, hasMultipleCarts }
}

export const useCart = () => {
    const callApi = useAxios()
    const [targetPerson] = useTargetPerson()

    const data = {
        targetPersonId: targetPerson.id,
        targetPersonFriendlyName: targetPerson.friendlyName,
    }

    return useQuery(
        [CART_KEY, targetPerson?.id],
        () =>
            callApi({
                url: 'api/cart',
                method: 'POST',
                data,
            }),
        {
            onSuccess: () => {
                refreshAllCarts()
            },
        },
    )
}

export const useDefaultLineManager = (
    targetPersonId: any,
    enabled: boolean,
) => {
    const callApi = useAxios()

    return useQuery(
        [CART_KEY, 'CART_LINE_MANAGER', targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/cart/defaultApprover?targetPersonId=${targetPersonId}`,
            }).then((data) => data.data),
        {
            enabled: enabled,
        },
    )
}

export const useUpdateCartItemComment = () => {
    const callApi = useAxios()
    const [targetPerson] = useTargetPerson()

    return useMutation(
        (data) =>
            callApi({
                method: 'PUT',
                url: '/api/cart/item/comment',
                data: data,
            }),
        {
            onSuccess: () => refreshTargetPersonCart(targetPerson.id),
        },
    )
}

export const useAddItemToCart = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    const [targetPerson] = useTargetPerson()
    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: '/api/cart/addItem',
                data,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('Common_ItemAddedToCart'))
                removeTargetPersonCartEvaluation(targetPerson.id)
                refreshTargetPersonCart(targetPerson.id)
            },
            onError: () => {},
        },
    )
}

export const useAddItemsToCart = () => {
    const callApi = useAxios()
    const { showSuccessMessage } = useNotification()
    const { t } = useTranslation()

    const [targetPerson] = useTargetPerson()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: '/api/cart/addItems',
                data,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('Common_ItemAddedToCart', { count: 2 }))
                removeTargetPersonCartEvaluation(targetPerson.id)
                refreshTargetPersonCart(targetPerson.id)
            },
            onError: () => {},
        },
    )
}

export const useRemoveItemFromCart = () => {
    const callApi = useAxios()
    const [targetPerson] = useTargetPerson()

    return useMutation(
        (id) =>
            callApi({
                method: 'DELETE',
                url: `/api/cart/deleteItem/${id}/${targetPerson.id}`,
            }),
        {
            onSuccess: () => {
                removeTargetPersonCartEvaluation(targetPerson.id)
                refreshTargetPersonCart(targetPerson.id)
            },
        },
    )
}

export const useRemoveCart = (targetPersonId: any) => {
    const callApi = useAxios()
    const [targetPerson] = useTargetPerson()

    return useMutation(
        () =>
            callApi({
                method: 'DELETE',
                url: `/api/cart/delete/${targetPersonId}`,
            }),
        {
            onSuccess: () => {
                removeTargetPersonCartEvaluation(targetPersonId)
                refreshTargetPersonCart(targetPerson.id)
            },
        },
    )
}

export const useEvaluateCart = () => {
    const callApi = useAxios()
    const [targetPerson] = useTargetPerson()

    return useMutation(
        () =>
            callApi({
                method: 'PUT',
                url: `/api/cart/evaluate/${targetPerson.id}`,
            }).then((response) => response.data),
        {
            onSuccess: () => {
                refreshTargetPersonCart(targetPerson.id)
            },
        },
    )
}

export const usePreviousEvaluationResults = () => {
    const { data: cart } = useCart()

    const showRiskData =
        cart &&
        cart.cartItems.length > 0 &&
        !cart.requiresEvaluation &&
        cart.evaluationDateUtc !== null

    const callApi = useAxios()
    const [targetPerson] = useTargetPerson()

    return useQuery(
        [CART_KEY, 'PREVIOUS_EVALUATION', targetPerson?.id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/cart/evaluationResult/${targetPerson.id}`,
            }).then((response) => response.data),
        {
            enabled: showRiskData,
        },
    )
}

export const useRiskFunctions = (
    localRiskId: string,
    assigneeId: string,
    isLeftSide: boolean,
    inverted: boolean,
) => {
    const callApi = useAxios()

    return useQuery<IRiskFunction[]>(
        [
            CART_KEY,
            localRiskId,
            assigneeId,
            isLeftSide,
            inverted,
            'RISK_FUNCTIONS',
        ],
        () =>
            callApi({
                method: 'GET',
                url: `/api/cart/riskFunctions/${assigneeId}/${localRiskId}/${
                    isLeftSide ? 'left' : 'right'
                }?riskDetailsInversed=${inverted}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(localRiskId) && Boolean(assigneeId),
        },
    )
}

export const useSubmitCart = () => {
    const callApi = useAxios()
    const { data: currentPerson } = useCurrentPerson()
    const [targetPerson, setTargetPerson] = useTargetPerson()

    return useMutation(
        (data) =>
            callApi({
                method: 'PUT',
                url: '/api/cart/submit',
                data: data,
            }),
        {
            onSuccess: () => {
                removeTargetPersonCartEvaluation(targetPerson.id)
                setTargetPerson(currentPerson)
                refreshTargetPersonCart(currentPerson.id)
            },
        },
    )
}

export const usePredefinedJustifications = () => {
    const callApi = useAxios()
    return useQuery(
        [CART_KEY, 'PREDEFINED_JUSTIFICATIONS'],
        () =>
            callApi({
                method: 'GET',
                url: 'api/cart/predefinedJustifications',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}
export const useRenewableAssignmentInfo = (
    assigneeId: string,
    id: string,
    isBusinessRole: boolean,
    enable: boolean,
) => {
    const param = isBusinessRole
        ? `resourceAssignmentId=${id}`
        : `resourceId=${id}`
    const callApi = useAxios()
    return useQuery(
        [CART_KEY, 'RENEWABLE_ASSIGNMENT_INFO', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/Cart/RenewableAssignmentInfo?assigneeId=${assigneeId}&${param}`,
            }).then((data) => data.data),
        {
            staleTime: Infinity,
            enabled: enable,
        },
    )
}
