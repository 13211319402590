const filters = [
    {
        forRequestAccess: true,
        forManageAccess: true,
        name: 'ShoppingFor',
        requireAccess: {
            control: 'ITShop-ShopForTargetPerson-Control',
        },
        order: 1,
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'Applications',
        title: 'Common_Applications',
        order: 4,
        colorCode: {
            primary: '#ff5e65',
            secondary: '#fee8f2',
        },
        type: 'autocomplete',
        label: 'Common_Applications',
        placeholder: 'Common_SelectApplication',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        url: '/api/ProtectedAppResources',
        queryParams: {
            take: 25,
        },
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'application',
        queryParam: {
            name: 'applicationId',
            valueProp: 'id',
        },
        basedOn: {
            type: 'required',
            contextType: 'global',
            contextProp: 'targetPerson',
            valueProp: 'id',
            queryParamName: 'targetPersonId',
        },
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'Status',
        title: 'ShowByStatus',

        order: 3,
        colorCode: {
            primary: 'rgb(93, 104, 112)',
            secondary: 'rgba(93, 104, 112, 0.09)',
        },
        type: 'nullableBoolean',
        optionConfig: {
            type: 'label',
            labelProp: 'name',
            trueLabel: 'ActiveCheckout',
            falseLabel: 'AvailableCheckout',
            nullLabel: 'Common_All',
        },
        contextProp: 'status',
        queryParam: {
            name: 'HasActiveCheckout',
        },
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'AccessType',
        title: 'AccessType',
        order: 3,
        colorCode: {
            primary: 'rgb(93, 104, 112)',
            secondary: 'rgba(93, 104, 112, 0.09)',
        },
        type: 'nullableBoolean',
        optionConfig: {
            type: 'label',
            labelProp: 'name',
            trueLabel: 'PreApproved',
            falseLabel: 'NotPreApproved',
            nullLabel: 'Common_All',
        },
        contextProp: 'accessType',
        queryParam: {
            name: 'ShowPreApproved',
        },
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        dynamic: true,
        name: 'Type',
        title: 'CredentialsType',
        order: 3,
        colorCode: {
            primary: 'rgb(93, 104, 112)',
            secondary: 'rgba(93, 104, 112, 0.09)',
        },
        type: 'select',
        placeholder: 'Common_All',
        optionConfig: {
            type: 'label',
            labelProp: 'name',
        },
        url: '/api/Credentials/types',
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'type',
        queryParam: {
            name: 'typeId',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        dynamic: true,
        name: 'Category',
        title: 'CredentialsCategory',
        order: 3,
        colorCode: {
            primary: 'rgb(93, 104, 112)',
            secondary: 'rgba(93, 104, 112, 0.09)',
        },
        type: 'select',
        placeholder: 'Common_All',
        optionConfig: {
            type: 'label',
            labelProp: 'name',
        },
        url: '/api/Credentials/categories',
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'category',
        queryParam: {
            name: 'categoryId',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        name: 'TargetSystem',
        title: 'Common_TargetSystem',

        order: 7,
        colorCode: {
            primary: '#307fc1',
            secondary: 'rgba(48, 127, 193, 0.09)',
        },
        contextProp: 'accountStore',
        type: 'autocomplete',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        queryParam: {
            name: 'AccountStoreGUID',
            type: 'guid',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        dynamic: true,
        name: 'AdvancedSearch',
        title: 'Common_AdvancedSearch',
        requireAccess: {
            control: 'ITShop-CredentialsAdvancedSearch-Control',
        },
        order: 8,
        icon: {
            name: 'AdvancedSearch',
            color: '#959598',
        },
        type: 'advancedSearch',
        forms: {
            title: 'Common_Forms',
            colorCode: {
                primary: '#2920ad',
                secondary: '#ebeaf7',
            },
            filters: [
                {
                    name: 'FriendlyName',
                    type: 'text',
                    placeholder: 'Common_FriendlyName',
                    contextProp: 'advancedSearch.forms.friendlyName',
                    queryParam: {
                        name: 'FriendlyName',
                        type: 'string',
                    },
                },
                {
                    name: 'LogonName',
                    type: 'text',
                    placeholder: 'Common_LogonName',
                    contextProp: 'advancedSearch.forms.logonName',
                    queryParam: {
                        name: 'LogonName',
                        type: 'string',
                    },
                },
                {
                    name: 'description',
                    type: 'text',
                    placeholder: 'Credentials_Description',
                    contextProp: 'advancedSearch.forms.description',
                    queryParam: {
                        name: 'Description',
                        type: 'string',
                    },
                },
            ],
        },
        tags: {
            title: 'Common_Tags',
            colorCode: {
                primary: '#f81983',
                secondary: '#fee8f2',
            },
            contextProp: 'advancedSearch.tags',
        },
    },
]

const attributes = [
    //Add columnName property as sorting property for Request Access and manageAccessColumnName property for Manage Access if sorting can't be done on name property
    {
        name: 'friendlyName',
        columnName: 'friendlyName',
        label: 'Display Name',
        isHeader: true,
        sortable: true,
        forHistory: true,
        headerCellStyles: {
            width: '190px',
            minWidth: '190px',
            maxWidth: '190px',
        },
    },

    {
        name: 'userName',
        columnName: 'userName',
        label: 'User Name',
        isHeader: true,
        manageAccessOnlyAttribute: true,
        headerCellStyles: {
            width: '150px',
            minWidth: '150px',
            maxWidth: '150px',
        },
        component: {
            name: 'CredentialsLockedAttribute',
            length: 10,
            locked: true,
            targetUrl: '/api/credentials/hiddenProperties',
            targetProperty: 'userName',
            validationMessage: 'CredentialCheckoutValidationMessage',
        },
    },
    {
        name: 'password',
        columnName: 'password',
        label: 'Password',
        isHeader: true,
        manageAccessOnlyAttribute: true,
        headerCellStyles: {
            width: '120px',
            minWidth: '120px',
            maxWidth: '120px',
        },
        component: {
            name: 'CredentialsLockedAttribute',
            length: 10,
            locked: true,
            targetUrl: '/api/credentials/hiddenProperties',
            targetProperty: 'password',
            validationMessage: 'CredentialCheckoutValidationMessage',
        },
    },
    {
        name: 'notes',
        columnName: 'notes',
        label: 'Notes',
        isHeader: true,
        manageAccessOnlyAttribute: true,
        headerCellStyles: {
            width: '150px',
            minWidth: '150px',
            maxWidth: '150px',
        },
        component: {
            name: 'CredentialsLockedAttribute',
            length: 10,
            locked: true,
            targetUrl: '/api/credentials/hiddenProperties',
            targetProperty: 'notes',
            validationMessage: 'CredentialCheckoutValidationMessage',
        },
    },

    {
        name: 'checkedOutByLogin',
        columnName: 'checkedOutByLogin',
        label: 'Account Logon Name',
        isHeader: true,
        sortable: true,
        forManageAccess: false,
    },
    {
        name: 'description',
        columnName: 'description',
        label: 'Description',
        isHeader: false,
        sortable: true,
        forManageAccess: false,
    },
    {
        name: 'resourceSystemFriendlyName',
        columnName: 'resourceSystemFriendlyName',
        label: 'Domain or Directory',
        isHeader: true,
        sortable: true,
        forManageAccess: false,
        headerCellStyles: {
            width: '60px',
            minWidth: '60px',
            maxWidth: '80px',
            fontSize: '13px',
        },
        styles: {
            cursor: 'pointer',
            width: '60px',
            minWidth: '60px',
            maxWidth: '80px',
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'statusButton',
        label: 'Status',
        hideValue: true,
        manageAccessOnlyAttribute: true,
        headerCellStyles: {
            width: '100px',
            minWidth: '120px',
            maxWidth: '120px',
            fontSize: '13px',
        },
        component: {
            name: 'CredentialStatusButton',
        },
    },
    {
        name: 'remainingTime',
        hideValue: true,
        label: 'RemainingTime',
        manageAccessOnlyAttribute: true,
        headerCellStyles: {
            width: '100px',
            minWidth: '120px',
            maxWidth: '120px',
            fontSize: '13px',
        },
        component: {
            name: 'CredentialRemainingTime',
        },
    },
]
const checkoutHistoryCardAttributes = [
    {
        name: 'businessRequestName',
        label: 'businessRequest',
        redirectToOtherMS: {
            microservice: 'myTasks',
            path: '/myRequests/businessRequests',
            queryParams: [{ key: 'id', value: 'businessRequestId' }],
        },
    },
    {
        name: 'businessRequestItemName',
        label: 'businessRequestItem',
        redirectToOtherMS: {
            microservice: 'myTasks',
            path: '/myRequests/businessRequestItems',
            queryParams: [{ key: 'id', value: 'businessRequestItemId' }],
        },
    },
    {
        name: 'startTimeUtc',
        label: 'startTime',
        component: {
            componentName: 'DateTimeGenericComponent',
        },
    },
    {
        name: 'endTimeUtc',
        label: 'endTime',
        component: {
            componentName: 'DateTimeGenericComponent',
        },
    },
    {
        name: 'actualCheckedOutTimeUtc',
        label: 'actualCheckOutTime',
    },
]

const credentialsConfig = {
    name: 'Credentials',
    resourceTypeName: 'ExternalCredential',
    title: 'Common_Credentials',
    route: '/Credentials',
    requireAccess: {
        page: 'ITShop-Credentials-Page',
    },
    order: 11,
    icon: {
        small: 'CredentialsSmall',
        large: 'CredentialsLarge',
    },
    dataSource: {
        type: 'MS_DS',
        url: '/api/credentials',
    },
    workflows: [],
    filters: filters,
    attributes: attributes,
    checkoutHistoryCardAttributes,
}

export default credentialsConfig
