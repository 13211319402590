import React, { Fragment } from 'react'
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableSortLabel,
    makeStyles,
    Box,
} from '@material-ui/core'
import Button from './Button'
import clsx from 'clsx'
import { Loader, Tooltip } from 'packages/eid-ui'
import TextFormatter from '../ListingPage/TextFormatter'
import { useTranslation } from 'react-i18next'
import DynamicComponents from 'components/DynamicComponents'
import { ResourceListingInfoIcon } from 'components/DynamicComponents/ResourceListingInfoIcon'

const colors = {
    primary: '#307fc1',
    grey: '#7d7c7c',
}

const maxCharacters = 80

const tooltipStyles = {
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    padding: '8px',
    maxWidth: '500px',
}

var getValue = (ci, a) => {
    return ci[a.name] ?? ''
}

const useStyles = makeStyles({
    headGrey: {
        color: '#7d7c7c !important',
    },

    blue: {
        color: colors.primary,
    },
    boldBlue: {
        color: colors.primary,
        fontWeight: 'bold',
    },
    underline: {
        textDecoration: 'underline',
    },

    table: {
        backgroundColor: '#ffffff',
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
    },
    highlightedRow: {
        border: ' solid 1px #01ae8f',
        boxShadow: '4px 4px 15px 0 rgba(48, 127, 193, 0.15)',
    },

    tableCell: {
        maxWidth: '200px',
        overflow: 'hidden',
        padding: '15px 10px',
        fontSize: '13px',
        borderBottom: '0',
        '&:first-child': {
            padding: '0px 10px',
        },
    },

    tableCellHighlighted: {
        borderTop: ' solid 1px #01ae8f',
        padding: '15px 10px',
        fontSize: '13px',
        borderBottom: '0',
        '&:first-child': {
            padding: '15px 20px',
        },
    },
    hilightFont: {
        fontSize: '15px',
    },
    tableHeaderCell: {
        maxWidth: '200px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '13px !important',
        backgroundColor: '#fbfbfd',
        '& svg': {
            margin: '0px !important',
        },
    },
    rowSelect: {
        borderBottom: '1px solid grey',
        borderRadius: '0',
    },
    tablePagination: {
        color: 'grey !important',
    },

    tableSortLabel: {
        color: '#7d7c7c !important',
        fill: '#7d7c7c !important',

        '& svg': {
            color: `#D2D2D9 !important`,
            marginLeft: '4px !important',
        },

        '&:hover': {
            color: `${colors.primary} !important`,

            '& svg': {
                color: `${colors.primary} !important`,
            },
        },
    },
    activeTableSortLabel: {
        '& svg': {
            color: `${colors.primary} !important`,
        },
    },

    bold1: {
        color: '#000000',
        fontSize: '15px',
        fontWeight: 'bold',
    },
})

const renderComponent = (attribute, data) => {
    const DynamicComponentToRender = DynamicComponents[attribute.component.name]

    return <DynamicComponentToRender attribute={attribute} data={data} />
}

const TableView = ({
    fallbackMessage,
    data,
    attributes,
    onItemClick,
    loading,
    onSort,
    sortBy,
    sortOrder,
}) => {
    const { t } = useTranslation()
    const toolTipValue = (items) => {
        const newItems = []
        items.map((item) => newItems.push(item.friendlyName))
        return newItems.join(`, `)
    }

    const filteredAttributes = attributes.filter(
        (a) => !a.manageAccessOnlyAttribute,
    )
    const headings = filteredAttributes.map((a) => ({
        ...a,
        label: t(a.label),
        sort: a.sortable ? a.sortable : false,
    }))

    const classes = useStyles({})

    const handleSort = (data) => {
        const sortingProperty =
            data.columnName && data.columnName.length > 0
                ? data.columnName
                : data.name

        onSort(
            data,
            sortBy !== sortingProperty
                ? 'desc'
                : !sortOrder || sortOrder === '' || sortOrder === 'asc'
                ? 'desc'
                : 'asc',
        )
    }

    return (
        <>
            <Table id="identities_list_table" className={classes.table}>
                <TableHead>
                    <TableRow className={classes.tableRow}>
                        {headings.map((heading, index) => {
                            const sortingProperty =
                                heading.columnName &&
                                heading.columnName.length > 0
                                    ? heading.columnName
                                    : heading.name

                            return (
                                <TableCell
                                    key={'pd-head-cell-' + index}
                                    className={clsx(
                                        classes.tableCell,
                                        classes.headGrey,
                                        classes.tableHeaderCell,
                                    )}
                                    align={heading.align}
                                >
                                    {!heading.sort ? (
                                        <span style={{ display: 'flex' }}>
                                            {heading.label}
                                        </span>
                                    ) : (
                                        <span style={{ display: 'flex' }}>
                                            <Tooltip
                                                title={
                                                    sortBy ===
                                                        sortingProperty &&
                                                    sortOrder === 'desc'
                                                        ? t(
                                                              'Common_SortAscending',
                                                          )
                                                        : t(
                                                              'Common_SortDescending',
                                                          )
                                                }
                                            >
                                                <TableSortLabel
                                                    active={heading.sort}
                                                    direction={
                                                        sortBy ===
                                                        sortingProperty
                                                            ? sortOrder
                                                            : 'asc'
                                                    }
                                                    className={
                                                        sortBy !==
                                                        (heading.columnName &&
                                                        heading.columnName
                                                            .length > 0
                                                            ? heading.columnName
                                                            : heading.name)
                                                            ? classes.tableSortLabel
                                                            : clsx(
                                                                  classes.tableSortLabel,
                                                                  classes.activeTableSortLabel,
                                                              )
                                                    }
                                                    style={{
                                                        color:
                                                            sortBy ===
                                                                sortingProperty &&
                                                            'yellow !important',
                                                    }}
                                                    onClick={() =>
                                                        handleSort(heading)
                                                    }
                                                >
                                                    {heading.label}
                                                </TableSortLabel>
                                            </Tooltip>
                                        </span>
                                    )}
                                </TableCell>
                            )
                        })}
                        <TableCell
                            className={clsx(
                                classes.tableHeaderCell,
                                classes.tableCell,
                                classes.headGrey,
                            )}
                        />
                    </TableRow>
                </TableHead>

                <TableBody>
                    {loading && (
                        <TableRow>
                            <TableCell
                                colSpan={headings.length + 1}
                                style={{ textAlign: 'center' }}
                            >
                                <Loader />
                            </TableCell>
                        </TableRow>
                    )}
                    {data &&
                        (data.length === 0 ? (
                            <TableRow className={classes.tableRow}>
                                <TableCell
                                    colSpan={headings.length + 1}
                                    style={{ textAlign: 'center' }}
                                >
                                    {fallbackMessage}
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {data.map((item) => (
                                    <TableRow
                                        key={'pd-body-row' + item.id}
                                        className={classes.tableRow}
                                    >
                                        {filteredAttributes.map((a) => {
                                            let wrappedCellContent

                                            if (a.component) {
                                                wrappedCellContent = renderComponent(
                                                    a,
                                                    item,
                                                )
                                            } else if (a.type !== 'array') {
                                                const cellContent = (
                                                    <Box
                                                        maxHeight="200px"
                                                        display="flex"
                                                        maxWidth="200px"
                                                        overflow="auto"
                                                        minHeight="50px"
                                                        style={{
                                                            display: 'flex',
                                                            wordBreak:
                                                                'break-word',
                                                            wordWrap:
                                                                'break-word',
                                                            alignItems:
                                                                'center',
                                                            ...a.style,
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: a.moreInfoIcon
                                                                    ? '70%'
                                                                    : '100%',
                                                            }}
                                                        >
                                                            {getValue(item, a)
                                                                .length >
                                                            maxCharacters
                                                                ? `${getValue(
                                                                      item,
                                                                      a,
                                                                  ).substring(
                                                                      0,
                                                                      maxCharacters,
                                                                  )}...`
                                                                : getValue(
                                                                      item,
                                                                      a,
                                                                  )}{' '}
                                                        </div>
                                                        {a.moreInfoIcon && (
                                                            <ResourceListingInfoIcon
                                                                attribute={a}
                                                                data={item}
                                                            />
                                                        )}
                                                    </Box>
                                                )
                                                wrappedCellContent =
                                                    getValue(item, a).length >
                                                    maxCharacters ? (
                                                        <Tooltip
                                                            title={getValue(
                                                                item,
                                                                a,
                                                            )}
                                                            color="#5D6870"
                                                            fontColor="#ffffff"
                                                            enterDelay={500}
                                                            enterNextDelay={500}
                                                            interactive={true}
                                                            tooltipStyles={
                                                                tooltipStyles
                                                            }
                                                        >
                                                            {cellContent}
                                                        </Tooltip>
                                                    ) : (
                                                        cellContent
                                                    )
                                            } else {
                                                wrappedCellContent = (
                                                    <TextFormatter
                                                        style={{
                                                            textDecoration:
                                                                'underline',
                                                        }}
                                                        value={toolTipValue(
                                                            getValue(item, a),
                                                        )}
                                                    />
                                                )
                                            }
                                            return (
                                                <TableCell
                                                    key={`${item.id}${a.name}`}
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    {wrappedCellContent}
                                                </TableCell>
                                            )
                                        })}
                                        <TableCell
                                            className={classes.tableCell}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <Button
                                                onClick={() =>
                                                    onItemClick(item)
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </>
                        ))}
                </TableBody>
            </Table>
        </>
    )
}

export default TableView
