import { useState, useEffect } from 'react'
import {
    Box,
    styled,
    createStyles,
    makeStyles,
    Grid,
    Paper,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useCredentialOwners } from 'hooks'
import { ItemDetails, PeopleList } from 'components'
import { useIsSmallScreen } from 'packages/core'
import WorkflowActions from 'components/WorkflowActions'
import LockedPasswordBox from 'components/DynamicComponents/LockedPasswordBox'
import { Icon } from 'packages/eid-icons'
import CheckOutButton from 'components/DynamicComponents/CheckOutButton'
import CheckInButton from 'components/DynamicComponents/CheckInButton'
import { RemainingTime } from 'components/DynamicComponents/CredentialRemainingTime'
import { useResourceTypeContext } from 'resourceTypeContext'
import DateTimePicker from 'components/DateTimePicker'
import appConfiguration from 'appConfiguration'
import CheckoutHistoryCard from 'components/CheckoutHistoryCard'
import { useCheckOutCredentialsRecords } from 'hooks'
import { isNilOrEmpty } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import { Person } from 'components/ApplicationBanner/PersonDetails'
import moment from 'moment'
import { MasterPasswordLock } from 'components'
import { useAppState } from 'appContext'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const useStyles = makeStyles(
    createStyles({
        root: {
            paddingTop: '25px',
            padding: '20px 10px',
        },
        sectionHeader: {
            overflowWrap: 'break-word',
            wordBreak: 'normal',
            textTransform: 'uppercase',
            color: '#000000 !important',
            overflow: 'hidden',
            lineHeight: '15px',
            fontSize: '16px',
            padding: '0px 10px 20px 0px',
            fontWeight: 'bold',
            backgroundImage:
                'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
            backgroundSize: '8.00px 8.00px',
            '& svg': {
                margin: '0px !important',
            },
        },
    }),
)

const PromptContainer = styled('div')({
    padding: '48px 31px 20px 31px',
    minHeight: '232px',
    display: 'flex',
})

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const OverviewSection = ({ item }) => {
    const [{ masterPassword }] = useAppState()
    const credential = item.resource

    const { t } = useTranslation()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const hiddenPropsBaseUrl = '/api/credentials/hiddenProperties'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('DisplayName')}
                    value={credential.friendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <div style={{ position: 'relative' }}>
                    {!masterPassword && (
                        <MasterPasswordLock
                            style={{ height: 140, width: '100%' }}
                        />
                    )}
                    <div>
                        <ItemDetails.Attribute
                            label={t('UserName')}
                            value={
                                <LockedPasswordBox
                                    targetUrl={`${hiddenPropsBaseUrl}/${credential.id}`}
                                    isClickable={!credential.canCheckOut}
                                    validationMessage={t(
                                        'CredentialCheckoutValidationMessage',
                                    )}
                                    targetProperty="userName"
                                />
                            }
                            orientation={attributeOrientation}
                            valueProps={overflowStyleProps}
                        />

                        <ItemDetails.Attribute
                            label={t('Password')}
                            value={
                                <LockedPasswordBox
                                    targetUrl={`${hiddenPropsBaseUrl}/${credential.id}`}
                                    isClickable={!credential.canCheckOut}
                                    validationMessage={t(
                                        'CredentialCheckoutValidationMessage',
                                    )}
                                    targetProperty="password"
                                />
                            }
                            orientation={attributeOrientation}
                            valueProps={overflowStyleProps}
                        />

                        <ItemDetails.Attribute
                            label={t('Notes')}
                            value={
                                <LockedPasswordBox
                                    targetUrl={`${hiddenPropsBaseUrl}/${credential.id}`}
                                    isClickable={!credential.canCheckOut}
                                    validationMessage={t(
                                        'CredentialCheckoutValidationMessage',
                                    )}
                                    targetProperty="notes"
                                />
                            }
                            orientation={attributeOrientation}
                            valueProps={overflowStyleProps}
                        />
                    </div>
                </div>
                <ItemDetails.Attribute
                    label={t('AccountLogonName')}
                    value={credential.checkedOutByLogin}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('DomainOrDirectory')}
                    value={credential.resourceSystemFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('CheckedOutBy')}
                    value={
                        credential.checkedOutByPersonId && (
                            <Person
                                personId={credential.checkedOutByPersonId}
                            />
                        )
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('AllowMultipleCheckout')}
                    value={
                        credential.allowMultiCheckOut
                            ? t('Common_True')
                            : t('Common_False')
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('CheckoutTime')}
                    value={moment
                        .utc(credential?.currentCheckOutStartTimeUtc)
                        .clone()
                        .local()
                        .format('L LT')}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('RemainingTime')}
                    value={
                        <RemainingTime
                            startTime={credential?.currentCheckOutStartTimeUtc}
                            endTime={credential?.currentCheckOutEndTimeUtc}
                        />
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('AccessType')}
                    value={
                        credential.isPreApproved
                            ? t('CredentialPreApprovedAccess')
                            : t('CredentialOneTimeAccess')
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('CredentialType')}
                    value={credential.typeName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('CredentialCategory')}
                    value={credential.categoryName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Description')}
                    value={credential.description}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}

const AdditionalInformationSection = ({ item }) => {
    const credential = item.resource
    const { t } = useTranslation()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const { data: owners, isLoading: ownersLoading } = useCredentialOwners(
        credential.id,
    )

    return (
        <>
            {ownersLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <ItemDetails.AttributesContainer bottomPadding="0">
                    <PaddedDiv>
                        <ItemDetails.Attribute
                            label={t('Common_Owners')}
                            value={<PeopleList list={owners} />}
                            orientation={attributeOrientation}
                        />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            )}
        </>
    )
}

const CheckOutHistory = ({ item }) => {
    const { t } = useTranslation()
    const [state, dispatch] = useResourceTypeContext()
    const [spinner, setSpinner] = useState(true)

    const configuration = appConfiguration
    const checkoutHistoryAttributes = configuration.resourceTypes.filter(
        (a) => a.name === 'Credentials',
    )[0].checkoutHistoryCardAttributes

    const queryData = {}
    if (state.credentialCheckoutHistory.startDateFilter) {
        queryData.StartDateAfter =
            state.credentialCheckoutHistory.startDateFilter
    }
    if (state.credentialCheckoutHistory.endDateFilter) {
        queryData.EndDateBefore = state.credentialCheckoutHistory.endDateFilter
    }
    const classes = useStyles()
    const {
        list: history,
        loading: historyLoading,
        pagination,
    } = useCheckOutCredentialsRecords(item.resource.id, queryData)
    useEffect(() => {
        setTimeout(() => setSpinner(false), 1000)
    }, [history])

    return (
        <Box style={{ background: '#fff' }}>
            <Box
                style={{
                    padding: '30px 0px 0px 30px',
                    display: 'flex',
                    width: '90%',
                }}
            >
                <DateTimePicker
                    disabled={historyLoading}
                    disableFuture
                    boxStyle={{
                        style: { maxWidth: '280px' },
                    }}
                    label={t('StartDate')}
                    value={
                        isNilOrEmpty(
                            state.credentialCheckoutHistory['startDateFilter'],
                        )
                            ? null
                            : state.credentialCheckoutHistory['startDateFilter']
                                  .clone()
                                  .local()
                    }
                    onChange={(date) => {
                        const dateToSet = date.clone()
                        dateToSet.startOf('day')
                        dateToSet.utc()

                        dispatch({
                            type: 'SET_CHECKOUT_HISTORY_FILTERS',
                            payload: {
                                key: 'startDateFilter',
                                value: dateToSet,
                            },
                        })
                    }}
                    handleClear={() =>
                        dispatch({
                            type: 'SET_CHECKOUT_HISTORY_FILTERS',
                            payload: {
                                key: 'startDateFilter',
                                value: null,
                            },
                        })
                    }
                />
                <DateTimePicker
                    disabled={historyLoading}
                    boxStyle={{
                        style: { marginLeft: '20px', maxWidth: '280px' },
                    }}
                    disableFuture
                    minDate={
                        state.credentialCheckoutHistory['startDateFilter']
                            ? state.credentialCheckoutHistory['startDateFilter']
                            : null
                    }
                    label={t('EndDate')}
                    value={
                        isNilOrEmpty(
                            state.credentialCheckoutHistory['endDateFilter'],
                        )
                            ? null
                            : state.credentialCheckoutHistory['endDateFilter']
                                  .clone()
                                  .local()
                    }
                    onChange={(date) => {
                        const dateToSet = date.clone()
                        dateToSet.startOf('day')
                        dateToSet.utc()

                        dispatch({
                            type: 'SET_CHECKOUT_HISTORY_FILTERS',
                            payload: {
                                key: 'endDateFilter',
                                value: dateToSet,
                            },
                        })
                    }}
                    handleClear={() =>
                        dispatch({
                            type: 'SET_CHECKOUT_HISTORY_FILTERS',
                            payload: {
                                key: 'endDateFilter',
                                value: null,
                            },
                        })
                    }
                />
            </Box>
            {historyLoading || spinner ? (
                <div style={{ alignSelf: 'center' }}>
                    <Loader />
                </div>
            ) : (
                <Paper className={classes.root}>
                    <Grid
                        container
                        spacing={2}
                        style={{ justifyContent: 'start' }}
                    >
                        {history.map((item, index) => (
                            <CheckoutHistoryCard
                                key={index}
                                item={item}
                                attributes={checkoutHistoryAttributes}
                            />
                        ))}
                    </Grid>
                    <Box
                        display="flex"
                        marginTop={'20px'}
                        justifyContent="center"
                    >
                        {pagination}
                    </Box>
                </Paper>
            )}
        </Box>
    )
}

export const ActionsSection = (props) => {
    const classes = useStyles()

    return (
        <>
            <PromptContainer>
                {props.iconName ? (
                    <Box width="172px" display="flex" flexDirection="column">
                        <Box className={classes.sectionHeader}>
                            {props.sectionLabel}
                        </Box>
                        <Icon
                            name={props.iconName}
                            height="150px"
                            width="150px"
                            color="#D2D2D9"
                        />
                    </Box>
                ) : (
                    <></>
                )}
                <Box width="auto" paddingLeft="16px">
                    {props.children}
                </Box>
            </PromptContainer>
        </>
    )
}

const CredentialDetails = ({ item, toggleDrawer }) => {
    const { t } = useTranslation()
    const [{ masterPassword }] = useAppState()
    const [activeTab, setActiveTab] = useState('overview')

    return (
        <>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />

                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
                <ItemDetails.Tabs.Tab
                    value="checkouts"
                    label={t('Checkouts')}
                />
            </ItemDetails.Tabs>

            {activeTab === 'overview' && <OverviewSection item={item} />}

            {activeTab === 'additionalInformation' && (
                <AdditionalInformationSection item={item} />
            )}
            {activeTab === 'checkouts' && <CheckOutHistory item={item} />}

            <ActionsSection
                sectionLabel={t('Available Actions')}
                iconName="SettingsDouble"
            >
                <WorkflowActions
                    resource={item.resource}
                    route={'credentials'}
                />
            </ActionsSection>

            <div style={{ position: 'relative' }}>
                {!masterPassword && (
                    <MasterPasswordLock
                        style={{ height: '100%', width: '100%' }}
                    />
                )}
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent="flex-start"
                    style={{ padding: '20px 20px 10px 20px' }}
                >
                    {item?.resource?.canCheckOut && (
                        <CheckOutButton data={item} drawerView={true} />
                    )}
                    {item?.resource?.canCheckIn && (
                        <CheckInButton data={item} drawerView={true} />
                    )}
                </Box>
            </div>
        </>
    )
}

export default CredentialDetails
