import config from 'config'

export const paginate = (items, pageNumber, pageSize) => {
    const startIndex = (pageNumber - 1) * pageSize
    const end = startIndex + pageSize
    items = items.slice(startIndex, end)
    return items
}

export const sanitizeSearchString = (searchString) => {
    return searchString && typeof searchString === 'string'
        ? searchString.trim()
        : ''
}

export const isValidSearchString = (searchString) => {
    return searchString && searchString.length > 0
}

export const getFullImageUrl = (imagePath) =>
    `${config.BASE_EID_URL}${imagePath}`

export { default as genericHashLink } from './genericHashLink'

export const subHeaderHeight = 70

export const bindResourceTypeNounAndVerbQueryParams = (resourceType) => {
    switch (resourceType) {
        case 'applications':
            return 'noun=ITShopMS&verb=Application'
        case 'businessRoles':
            return 'noun=ITShopMS&verb=BusinessRole'
        case 'applicationRoles':
            return 'noun=ITShopMS&verb=ApplicationRole'
        case 'azureLicenses':
            return 'noun=ITShopMS&verb=AzureLicense'
        case 'azureRoles':
            return 'noun=ITShopMS&verb=AzureRole'
        case 'managementRoles':
            return 'noun=ITShopMS&verb=ManagementRole'
        case 'mailboxes':
            return 'noun=ITShopMS&verb=Mailbox'
        case 'sharedFolders':
            return 'noun=ITShopMS&verb=SharedFolder'
        case 'computers':
            return 'noun=ITShopMS&verb=Computer'
        case 'credentials':
            return 'noun=ITShopMS&verb=Credential'

        default:
            return 'noun=ITShopMS&verb=Application'
    }
}
