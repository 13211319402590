import { FC } from 'react'
import { Box, Paper } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useUiActionsByResource } from 'hooks'
import { Skeleton } from '@material-ui/lab'
import { useHistory } from 'react-router'
import { useQuery } from 'packages/core'

const smallScreenWidth = 960
const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
            background: '#fbfbfd',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        paddingdiv: {
            padding: '0 20px',
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
        },
        innerdiv: {
            marginRight: '25px',
            width: '160px',
            padding: '4px 8px',
            fontSize: '11px',
            color: '#307fc1;',
            boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.05) !important',
            textTransform: 'capitalize',
            marginBottom: '20px',
            border: '1px solid #fff',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    }),
)

export const ApplicationActions: FC<any> = ({ data }) => {
    const classes = useStyles()
    const { data: applicationActions, isLoading } = useUiActionsByResource(
        data.id,
    )
    const query = useQuery()
    const history = useHistory()
    const handleActionClick = (item: any) => {
        query.delete('selected')
        query.set('workflow', item.requestWorkflowName)
        history.push(`/applications?${query.toString()}`)
    }
    return (
        <Paper className={classes.root}>
            {isLoading ? (
                <Box
                    style={{ display: 'block', width: '100%' }}
                    paddingRight={4}
                >
                    <Skeleton height={40} />
                </Box>
            ) : (
                <Box className={classes.paddingdiv}>
                    {applicationActions &&
                        applicationActions.length > 0 &&
                        applicationActions.map((item: any, index: number) => (
                            <Paper
                                key={`action-item-${index}`}
                                className={classes.innerdiv}
                                onClick={() => handleActionClick(item)}
                            >
                                {item.friendlyName}
                            </Paper>
                        ))}
                </Box>
            )}
        </Paper>
    )
}
