import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card } from '@material-ui/core'
import RequestableResourceDetails from 'components/RequestableResourceDetails'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'components'
import ItemHeader from './ItemHeader'
import { useTranslation } from 'react-i18next'
import { useTargetPerson, useAddItemToCart } from 'hooks'
import { WarningModal } from 'packages/eid-ui'
import cartHelpers from 'containers/Cart/cartHelpers'

const useStyles = makeStyles({
    root: (props) => ({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 'none !important',
        position: 'relative',
        overflow: 'visible',
        boxShadow: 'none !important',
        border: '1px solid transparent !important',
        '&:hover': {
            borderBottomRightRadius: '0 !important',
            borderBottomLeftRadius: '0 !important',

            overflow: 'visible',

            zIndex: 4,

            '&:not(:hover)': {
                zIndex: 2,
                transition: 'z-index 0.5s ease 0s',
            },
        },
        ...props.cardStyles,
    }),
    contentBox: {
        '&:not(:last-child)': {
            marginBottom: '18px',
        },
        position: 'relative',
    },
})

const AccessItem = ({
    resourceType,
    item,
    onItemClick,
    buttonStyles,
    attributes,
    color,
    cardStyles,
}) => {
    const headerAttribute = attributes.find((x) => x.isHeader)

    const [targetPerson] = useTargetPerson()
    const [showConfirm, setConfirm] = useState(false)

    const [addItemToCart, { isLoading: isAdding }] = useAddItemToCart()

    const isRevokable = item.assignment.assignmentDetails.isRevokable
    const { t } = useTranslation()

    const classes = useStyles({ cardStyles })

    const handleRevoke = () => {
        const itemToRevoke = cartHelpers.accessItemToCartItem(
            resourceType,
            targetPerson,
            item,
            'Remove',
        )
        if (!itemToRevoke) return

        addItemToCart(itemToRevoke).then(() => setConfirm(false))
    }

    return (
        <Card classes={{ root: classes.root }} role="button">
            <Box className={classes.contentBox}>
                <ItemHeader title={item.resource[headerAttribute.name]} />
                <RequestableResourceDetails
                    item={item.resource}
                    attributes={attributes}
                    assignmentDetails={item.assignment.assignmentDetails}
                    color={color}
                    additionalData={item}
                    isManageAccess={true}
                />
            </Box>
            <Box padding="20px" width="100%" display="flex">
                <Button
                    onClick={onItemClick}
                    color="#56BEAC"
                    rootStylesProp={{
                        borderRadius: 0,
                        marginX: '2px',
                        width: isRevokable ? '50%' : '100%',
                        height: '50px',
                        ...buttonStyles,
                    }}
                >
                    {t('ManageAccess_Details')}
                </Button>

                {isRevokable && (
                    <>
                        <Button
                            onClick={() => setConfirm(true)}
                            color="#d0021b"
                            rootStylesProp={{
                                marginLeft: '30px',
                                borderRadius: 0,
                                marginX: '2px',
                                width: '50%',
                                height: '50px',
                                '&:hover': {
                                    backgroundColor: '#C2021B',
                                },
                            }}
                        >
                            {t('Common_Revoke')}
                        </Button>
                        <WarningModal
                            title={t('Common_Confirm')}
                            description={t(
                                'Common_ConfirmationBeforeRevocation',
                            )}
                            yesLabel={t('Common_Yes')}
                            noLabel={t('Common_No')}
                            open={showConfirm}
                            cancel={() => setConfirm(false)}
                            proceeding={isAdding}
                            proceed={handleRevoke}
                        />
                    </>
                )}
            </Box>
        </Card>
    )
}

AccessItem.propTypes = {
    item: PropTypes.object,
    onItemClick: PropTypes.func,
}

export default AccessItem
