import { Box } from '@material-ui/core'
import { LinkTile, VerticalTabs } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { isNilOrEmpty, useIsSmallScreen, useQuery } from 'packages/core'
import useConfiguration from 'useConfiguration'
import { useTranslation } from 'react-i18next'
import { useResourceTypes } from 'hooks'
import { Skeleton } from '@material-ui/lab'
import { useHistory } from 'react-router'

const NavigationTabs = (props) => {
    const { count } = props
    const history = useHistory()
    const {
        location: { pathname },
    } = history

    const query = useQuery()
    query.delete('selected')

    const { t } = useTranslation()
    const { resourceTypes } = useConfiguration()

    const applicationId = query.get('applicationId')

    const { data, isLoading } = useResourceTypes(applicationId)

    const resourceTypesToShow = isLoading
        ? []
        : applicationId
        ? resourceTypes.filter((r) => data.includes(r.resourceTypeName))
        : resourceTypes

    const areaName =
        !isNilOrEmpty(resourceTypesToShow) && pathname.split('/').length > 2
            ? pathname.split('/')[2].toLowerCase()
            : ''

    const isActive = (path) =>
        `/${areaName.toLowerCase()}` === path.toLowerCase()

    const isSmallScreen = useIsSmallScreen()

    if (isLoading) {
        return (
            <Box>
                <Skeleton height={40} />
                <Skeleton height={40} />
            </Box>
        )
    }

    return isSmallScreen ? (
        <>
            {!isNilOrEmpty(resourceTypesToShow) &&
                resourceTypesToShow.map((r) => (
                    <Box key={r.route} width="171px" paddingRight="5px">
                        <LinkTile
                            icon={
                                <Icon
                                    name={r.icon.large}
                                    color={
                                        isActive(r.route)
                                            ? '#848992'
                                            : '#D2D2D9'
                                    }
                                />
                            }
                            label={t(r.title)}
                            active={isActive(r.route)}
                            count={
                                !isNilOrEmpty(count) &&
                                resourceTypesToShow.findIndex((t) =>
                                    isActive(r.route),
                                ) > -1
                                    ? count
                                    : undefined
                            }
                            showLabelWithin
                            onClick={() =>
                                history.push(
                                    `/applications${
                                        r.route
                                    }${query.toString()}`,
                                )
                            }
                        />
                    </Box>
                ))}
        </>
    ) : (
        <>
            {!isNilOrEmpty(resourceTypesToShow) && (
                <VerticalTabs
                    value={resourceTypesToShow.findIndex((t) =>
                        isActive(t.route),
                    )}
                >
                    {resourceTypesToShow.map((r) => {
                        return (
                            <VerticalTabs.Tab
                                key={r.name}
                                to={`/applications${
                                    r.route
                                }?${query.toString()}`}
                                label={t(r.title)}
                                icon={
                                    r.icon && (
                                        <Icon
                                            name={r.icon.small}
                                            color="#000"
                                        />
                                    )
                                }
                                count={
                                    !isNilOrEmpty(count) &&
                                    resourceTypesToShow.findIndex((t) =>
                                        isActive(r.route),
                                    ) > -1
                                        ? count
                                        : undefined
                                }
                            />
                        )
                    })}
                </VerticalTabs>
            )}
        </>
    )
}

export default NavigationTabs
