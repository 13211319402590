import { withRouter } from 'react-router'
import { EidLogoIcon, Icon, ShoppingCartIcon } from 'packages/eid-icons'
import { Header, Tooltip } from 'packages/eid-ui'
import {
    Badge,
    Box,
    Divider,
    makeStyles,
    styled,
    Typography,
} from '@material-ui/core'
import { useQuery } from 'packages/core'
import { useAllCarts, useCart, useEnvironment } from 'hooks'
import { Cart } from 'components'
import LanguageMenu from './LanguageMenu'
import HelpMenu from './HelpMenu'
import AccountDropdown from './AccountDropdown'
import Search from './Search'
import CartDropdown from '../CartDropdown'
import { Skeleton } from '@material-ui/lab'
import useSubcomponents from 'useSubcomponents'

const StyledBadge = styled(Badge)({
    '& .MuiBadge-badge': {
        backgroundColor: 'rgb(255, 60, 0)',
        color: '#ffffff',
        height: '16px',
        minWidth: '12px',
        fontSize: '10px',
    },
})

const useStyles = makeStyles({
    multipleCartIndicator: {
        position: 'absolute',
        top: '-4px',
        right: 0,
        height: '8px',
        width: '8px',
        borderRadius: '50%',
        backgroundColor: '#ff3c00',
        zIndex: 3,
    },
})

const StyledBox = styled(Box)({
    '@media (max-width:1159px)': {
        display: 'none',
    },
})

const GroupedButtonContainer = styled(Box)({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#307fc1',
    height: '34px',

    border: '1px solid transparent',
    borderRadius: '5px',

    '& >div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '&:hover': {
            cursor: 'pointer',
        },
    },

    '& >div:first-child': {
        borderRight: '1px solid  #b4b4b4',
    },
})

const StyledChip = styled('div')({
    position: 'absolute',
    top: '11px',
    left: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ff6100 !important',
    color: '#ffffff',
    height: '13px',
    maxWidth: '48px',
    minWidth: '24px',
    transform: 'skew(-20deg)',
    borderRadius: '2px',
    '@media (max-width:1159px)': {
        left: '2px',
    },
})

const ITShopHeader = ({ history, config }) => {
    const query = useQuery()
    const cartOpen = query.get('cart') === 'visible'
    const classes = useStyles()

    const { canSeeSimpleTextSearch } = useSubcomponents()

    const { data: environmentName } = useEnvironment()

    const { data: cart } = useCart()

    const { hasMultipleCarts } = useAllCarts()

    return (
        <>
            <Header bgColor={config.bgColor}>
                <Header.IconLink
                    logo
                    href={config.showEidLogo ? '/' : undefined}
                    rightDivider={config.showEidLogo}
                >
                    {config.showEidLogo && <EidLogoIcon />}
                </Header.IconLink>
                <Header.IconLink logo href="/">
                    {config.appLogo.type === 'icon' ? (
                        <Icon name={config.appLogo.value} />
                    ) : (
                        <img
                            style={config.appLogo.style}
                            src={config.appLogo.value}
                            alt={config.appLogo.name}
                        />
                    )}
                </Header.IconLink>
                <StyledBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    {config.appTitle.type === 'text' ? (
                        <Header.Title>{config.appTitle.value}</Header.Title>
                    ) : (
                        <Header.IconLink logo href="/">
                            <img
                                style={config.appTitle.style}
                                src={config.appTitle.value}
                                alt={config.appTitle.name}
                            />
                        </Header.IconLink>
                    )}
                </StyledBox>
                {config.showEnvironment && environmentName && (
                    <Box position="relative" width="32px">
                        <Tooltip title={environmentName}>
                            <StyledChip>
                                <Typography
                                    noWrap
                                    style={{
                                        fontSize: '8px',
                                        padding: '0px 1.5px',
                                    }}
                                >
                                    {environmentName}
                                </Typography>
                            </StyledChip>
                        </Tooltip>
                    </Box>
                )}

                {config.search.show && canSeeSimpleTextSearch && (
                    <div
                        style={{
                            height: '100%',
                            display: 'flex',
                            flex: 1,
                            minWidth: '140px',
                            alignItems: 'center',
                            marginLeft: '20px',
                            width: '700px',
                            paddingLeft: '10px',
                        }}
                    >
                        <Search styleData={config.search.style} />
                    </div>
                )}
                <Header.RightSection>
                    <Header.Item
                        style={{ minWidth: '32px', padding: '0px 8px' }}
                    >
                        <LanguageMenu anchorPosition={{
                            right: '-32px', top: '54px',
                        }} />
                    </Header.Item>
                    <Header.Item
                        style={{ minWidth: '32px', padding: '0px 8px' }}
                    >
                        <HelpMenu />
                    </Header.Item>

                    <Header.Item
                        style={{ minWidth: '32px', padding: '0px 8px' }}
                    >
                        <AccountDropdown />
                    </Header.Item>

                    <Header.Item style={{ minWidth: '40px' }}>
                        <Divider
                            orientation="vertical"
                            variant="fullWidth"
                            style={{
                                backgroundColor: '#e8e8e8',
                                height: 26,
                            }}
                        />
                    </Header.Item>

                    <Header.Item style={{ marginRight: '35px' }}>
                        {!cart ? (
                            <Skeleton width="80px" height="34px" />
                        ) : (
                            <GroupedButtonContainer>
                                <Box
                                    width="45px"
                                    onClick={() => {
                                        query.set('cart', 'visible')
                                        history.push(
                                            `${
                                                history.location.pathname
                                            }?${query.toString()}`,
                                        )
                                    }}
                                >
                                    <StyledBadge
                                        badgeContent={cart.cartItems.length}
                                    >
                                        <ShoppingCartIcon
                                            color="#307FC1"
                                            width="27px"
                                            height="27px"
                                        />
                                    </StyledBadge>
                                </Box>

                                <CartDropdown />
                                {hasMultipleCarts && (
                                    <Box
                                        className={
                                            classes.multipleCartIndicator
                                        }
                                    />
                                )}
                            </GroupedButtonContainer>
                        )}
                    </Header.Item>
                </Header.RightSection>
            </Header>
            <Cart
                open={cartOpen}
                toggleDrawer={() => {
                    query.delete('cart')
                    history.push(
                        `${history.location.pathname}?${query.toString()}`,
                    )
                }}
            />
        </>
    )
}
export default withRouter(ITShopHeader)
