import {
    preApprovedOnlyFilterColors,
    sharedWithCurrentUserOnlyFilterColors,
    timeConstrainedOnlyFilterColors,
    directAssignedOnlyFilterColors,
    personalOnlyFilterColors,
    expringAccessOnlyFilterColors,
} from '../../appConfiguration/colors'

const filters = [
    {
        forRequestAccess: true,
        forManageAccess: true,
        name: 'ShoppingFor',
        requireAccess: {
            control: 'ITShop-ShopForTargetPerson-Control',
        },
        order: 1,
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyDirectAssigned',
        title: 'Common_ShowDirectAssignedOnly',
        requireAccess: {
            control: 'ITShop-DirectAssignedCredentials-Control',
        },
        order: 2,
        colorCode: directAssignedOnlyFilterColors,
        label: 'Common_ShowDirectAssignedOnly',
        type: 'checkbox',
        contextProp: 'showDirectAssignedOnly',
        trueValueIndicator: 'Common_ShowDirectAssignedOnly',
        queryParam: {
            name: 'showDirectAssignedOnly',
        },
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyTimeConstrained',
        title: 'ManageAccess_ShowTimeConstrained',
        requireAccess: {
            control: 'ITShop-TimeConstrainedCredentials-Control',
        },
        order: 3,
        colorCode: timeConstrainedOnlyFilterColors,
        label: 'ManageAccess_ShowTimeConstrained',
        type: 'checkbox',
        contextProp: 'showTimeConstrained',
        trueValueIndicator: 'ManageAccess_ShowTimeConstrained',
        queryParam: {
            name: 'showTimeConstrained',
        },
    },

    {
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyPersonal',
        title: 'Common_ShowPersonalOnly',
        requireAccess: {
            control: 'ITShop-PersonalCredentials-Control',
        },
        order: 4,
        colorCode: personalOnlyFilterColors,
        label: 'Common_PersonalOnly',
        type: 'checkbox',
        contextProp: 'showPersonalOnly',
        trueValueIndicator: 'Common_PersonalOnly',
        queryParam: {
            name: 'showPersonalOnly',
        },
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyPreApproved',
        title: 'Common_ShowOnlyPreApproved',
        requireAccess: {
            control: 'ITShop-PreApprovedCredentials-Control',
        },
        order: 5,
        colorCode: preApprovedOnlyFilterColors,
        label: 'Common_ShowOnlyPreApproved',
        type: 'checkbox',
        contextProp: 'showPreApproved',
        trueValueIndicator: 'Common_ShowOnlyPreApproved',
        queryParam: {
            name: 'showPreApproved',
        },
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlySharedWithCurrentUser',
        title: 'Common_ShowOnlySharedWithCurrentUser',
        requireAccess: {
            control: 'ITShop-SharedWithCurrentUserCredentials-Control',
        },
        order: 5,
        colorCode: sharedWithCurrentUserOnlyFilterColors,
        label: 'Common_ShowOnlySharedWithCurrentUser',
        type: 'checkbox',
        contextProp: 'showSharedWithCurrentUser',
        trueValueIndicator: 'Common_ShowOnlySharedWithCurrentUser',
        queryParam: {
            name: 'showSharedWithCurrentUser',
        },
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyExpiring',
        title: 'Common_ExpiringAccess',
        requireAccess: {
            control: 'ITShop-ExpiringCredentials-Control',
        },
        order: 6,
        colorCode: expringAccessOnlyFilterColors,
        label: 'Common_ExpiringAccess',
        type: 'checkbox',
        contextProp: 'showExpiring',
        trueValueIndicator: 'Common_ExpiringAccess',
        queryParam: {
            name: 'showExpiring',
        },
    },
    {
        forRequestAccess: true,
        name: 'TargetSystem',
        title: 'Common_TargetSystem',
        requireAccess: {
            control: 'ITShop-TargetSystem-Control',
        },
        order: 7,
        colorCode: {
            primary: '#307fc1',
            secondary: 'rgba(48, 127, 193, 0.09)',
        },
        contextProp: 'accountStore',
        type: 'autocomplete',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        queryParam: {
            name: 'AccountStoreGUID',
            type: 'guid',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        dynamic: true,
        name: 'AdvancedSearch',
        title: 'Common_AdvancedSearch',
        requireAccess: {
            control: 'ITShop-CredentialsAdvancedSearch-Control',
        },
        order: 8,
        icon: {
            name: 'AdvancedSearch',
            color: '#959598',
        },
        type: 'advancedSearch',
        forms: {
            title: 'Common_Forms',
            colorCode: {
                primary: '#2920ad',
                secondary: '#ebeaf7',
            },
            filters: [
                {
                    name: 'FriendlyName',
                    type: 'text',
                    placeholder: 'Common_FriendlyName',
                    contextProp: 'advancedSearch.forms.friendlyName',
                    queryParam: {
                        name: 'FriendlyName',
                        type: 'string',
                    },
                },
                {
                    name: 'LogonName',
                    type: 'text',
                    placeholder: 'Common_LogonName',
                    contextProp: 'advancedSearch.forms.logonName',
                    queryParam: {
                        name: 'LogonName',
                        type: 'string',
                    },
                },
                {
                    name: 'description',
                    type: 'text',
                    placeholder: 'Credentials_Description',
                    contextProp: 'advancedSearch.forms.description',
                    queryParam: {
                        name: 'Description',
                        type: 'string',
                    },
                },
            ],
        },
        tags: {
            title: 'Common_Tags',
            colorCode: {
                primary: '#f81983',
                secondary: '#fee8f2',
            },
            contextProp: 'advancedSearch.tags',
        },
    },
]

const attributes = [
    //Add columnName property as sorting property for Request Access and manageAccessColumnName property for Manage Access if sorting can't be done on name property
    {
        name: 'externalCredentialName',
        columnName: 'friendlyName',
        label: 'Friendly Name',
        isHeader: true,
        sortable: true,
    },
    {
        name: 'externalCredentialTypeName',
        columnName: 'externalCredentialTypeName',
        label: 'Type',
        isHeader: true,
        sortable: true,
    },

    {
        name: 'computerFriendlyName',
        columnName: 'computerFriendlyName',
        label: 'Computer',
        isHeader: true,
        sortable: true,
    },
    {
        name: 'personFriendlyName',
        columnName: 'personFriendlyName',
        label: 'Approver',
        isHeader: true,
        sortable: true,
    },
    {
        name: 'startTimeUtc',
        columnName: 'startTimeUtc',
        label: 'Start Time',
        isHeader: true,
        sortable: true,
    },
    {
        name: 'endTimeUtc',
        columnName: 'endTimeUtc',
        label: 'End Time',
        isHeader: true,
        sortable: true,
    },
    {
        name: 'userCheckedOutTimeUtc',
        columnName: 'userCheckedOutTimeUtc',
        label: 'Actual Checkout Time',
        isHeader: false,
        sortable: true,
    },
]

const config = {
    filters,
    attributes,
}

export default config
