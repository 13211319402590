import React from 'react'
import { Box, Fab, makeStyles } from '@material-ui/core'
import { Loader } from 'packages/eid-ui'

const drawerButtonStyles = makeStyles(() => ({
    root: ({ loading, color }) => ({
        height: '31px',
        padding:'7px 14px',
        border: `solid 1px ${color}`,
        backgroundColor: loading ? color : '#ffffff',
        boxShadow: '0 0 0 0',
        color: loading ? '#fff' : color,
        textTransform: 'capitalize !important',
        fontSize:'14px',
        marginLeft:'20px',
        '& svg':{
            marginLeft:'10px'
        },
        '& path': {
            fill: color,
            marginLeft:'10px'
        },
        '&:hover': {
            backgroundColor: color,
            color: 'white',
            '& path': {
                fill: '#ffffff',
            },
            '& span': {
                color: '#fff',
            },
        },
    }),
}))
const Button = ({ onClick, loading, children, color }) => {
    const classes = drawerButtonStyles({ loading, color })
    return (
        <Fab classes={classes} variant="extended" onClick={onClick}>
            {loading ? (
                <div
                    style={{
                        width: '12px',
                    }}
                >
                    <Loader
                        color="#ffffff"
                        height="100%"
                        size={0.5}
                        display="inline"
                    />
                </div>
            ) : (
                children
            )}
        </Fab>
    )
}

Button.defaultProps = {
    color: '#01ae8f',
}

export default Button
