import { Drawer, Loader } from 'packages/eid-ui'
import ItemHeader from '../ItemHeader'
import { isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import ItemsDetailsMobileWrapper from 'components/ItemsDetailsMobileWrapper'
import CredentialDetails from './CredentialDetails'
import { useCredential } from 'hooks'

const CredentialDrawer = ({ id, toggleDrawer, attributes }) => {
    const isSmallScreen = useIsSmallScreen()

    const { data: credential } = useCredential(id)

    return isSmallScreen ? (
        <ItemsDetailsMobileWrapper toggleDrawer={toggleDrawer}>
            <ItemHeader title={credential?.friendlyName} />
            {!credential && <Loader />}
            {credential && (
                <CredentialDetails
                    credential={credential}
                    toggleDrawer={toggleDrawer}
                    attributes={attributes}
                />
            )}
        </ItemsDetailsMobileWrapper>
    ) : (
        <Drawer
            width="1024px"
            open={!isNilOrEmpty(id)}
            toggleDrawer={toggleDrawer}
            stickyHeader
            closeOnOutsideClick
            header={<ItemHeader title={credential?.friendlyName} />}
        >
            {!credential && <Loader />}
            {credential && (
                <CredentialDetails
                    credential={credential}
                    toggleDrawer={toggleDrawer}
                />
            )}
        </Drawer>
    )
}

export default CredentialDrawer
