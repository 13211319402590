import React, { useState, useRef, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery, useIsSmallScreen } from 'packages/core'
import { useUiActionsByResource } from 'hooks/uiActionsHooks'
import { EidWorkflow, Dropdown, Loader, Tooltip } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import config from 'config'
import {
    makeStyles,
    createStyles,
    MenuItem,
    Button,
    Backdrop,
    styled,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { PasswordModal } from 'components/PasswordModal'
import { useAppState } from 'appContext'

const useBlueStyles = makeStyles(
    createStyles({
        root: (dropdownOpen) => ({
            position: 'relative',
            height: '31px',
            padding: '0px 0px 0px 0px',
            // borderRadius: '16px',

            boxShadow: '0 0 0 0',
            textTransform: 'capitalize',

            // backgroundColor: dropdownOpen ? '#307fc1' : 'transparent',
            // border: 'solid 1px #307fc1',

            // color: dropdownOpen ? 'white' : '#307fc1',
            zIndex: dropdownOpen ? '1202' : '0',
            '&:hover': {
                // backgroundColor: '#307fc1',
                // color: 'white',
                cursor: 'hand',
            },

            '& .MuiButton-endIcon': {
                margin: '0px !important',
            },
        }),
        dropdownAnchor: {
            position: 'absolute',
            top: '40px',
            left: '115px',
        },
    }),
)

const StyledBackdrop = styled(Backdrop)(() => ({
    zIndex: '1201',
}))

export const CredentialsActionsButton = ({ item }) => {
    const query = useQuery()
    const history = useHistory()
    const { t } = useTranslation()
    // const [actionsButtonIconColor, setActionsButtonIconColor] = useState(
    //     '#307fc1',
    // )

    const [{ masterPassword }, dispatch] = useAppState()
    const [open, setOpen] = useState('')

    const isSmallScreen = useIsSmallScreen()

    const [anchorEl, setAnchorEl] = useState(null)
    const buttonClasses = useBlueStyles(Boolean(anchorEl))

    const { data, isLoading } = useUiActionsByResource(
        item.id,
        Boolean(anchorEl),
    )

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const anchorRef = useRef(null)

    const closeMenu = () => {
        setAnchorEl(null)
        // setActionsButtonIconColor('#307fc1')
    }

    const onActionClicked = (workflowName) => {
        query.set('workflowName', workflowName)
        query.set('resourceID', item.id)
        history.push(`/workflows/credentials?${query.toString()}`)
    }

    if (!masterPassword) {
        return (
            <PasswordModal open={open} setOpen={setOpen}>
                <Button
                    onClick={() =>
                        setOpen(masterPassword ? 'addToCart' : 'enterPassword')
                    }
                    classes={buttonClasses}
                    startIcon={<Icon name="Settings" />}
                />
            </PasswordModal>
        )
    }

    return (
        <Fragment>
            <Button
                style={{ width: '50px', marginRight: '0px' }}
                onClick={openMenu}
                classes={buttonClasses}
                endIcon={<Icon name="Settings" />}
            >
                <span
                    ref={anchorRef}
                    className={buttonClasses.dropdownAnchor}
                />
            </Button>
            <StyledBackdrop
                sx={{ color: '#fff' }}
                open={Boolean(anchorEl)}
                onClick={closeMenu}
            />

            <Dropdown
                anchorEl={anchorRef.current}
                showCone={true}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                listStyles={{
                    width: 'auto',
                    paddingBottom: '0px',
                    '& > ul': {
                        width: 'auto',
                        paddingBottom: '7px',
                        paddingTop: '0px',
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                iconPosition={{
                    top: '-8px',
                }}
                disableScrollLock={false}
            >
                {isLoading && <Loader height={40} />}
                {data &&
                    data.map((d) =>
                        isSmallScreen ? (
                            <EidWorkflow
                                key={d.id}
                                baseEidUrl={config.BASE_EID_URL}
                                title={d.friendlyName}
                                workflowName={d.requestWorkflowName}
                                workflowParams={[
                                    `SelectedResourceID=${item.id}`,
                                ]}
                            >
                                <MenuItem onClick={closeMenu}>
                                    {d.friendlyName}
                                </MenuItem>
                            </EidWorkflow>
                        ) : (
                            <Tooltip title={d.friendlyName} key={d.id}>
                                <MenuItem
                                    style={{ width: 'auto' }}
                                    onClick={() => {
                                        closeMenu()
                                        onActionClicked(d.requestWorkflowName)
                                    }}
                                >
                                    {d.friendlyName}
                                </MenuItem>
                            </Tooltip>
                        ),
                    )}
            </Dropdown>
        </Fragment>
    )
}

export default CredentialsActionsButton
