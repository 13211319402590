import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, makeStyles, Dialog } from '@material-ui/core'
import { useIsSmallScreen } from 'packages/core'
import { IconButton, EidButton, TextInput } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { ItemDetails } from 'components'
import { TimeDurationControl } from 'components/TimeDurationControl'

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const mobileScreenWidth = '767px'

const useStyles = makeStyles((theme) => ({
    body: ({}) => ({
        backgroundColor: '#fbfbfd',
        padding: '24px 40px',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        display: 'flex',
        '&:focus': {
            outline: 'none',
        },
    }),
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '80px',
        alignItems: 'center',
        padding: '16px 12px 16px 32px',
        backgroundColor: '#ffffff',
    },
    closeIcon: {
        display: 'flex',
    },

    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#3b454d',
    },
    sectionHeader: {
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        textTransform: 'uppercase',
        color: '#000000 !important',
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '14px',
        padding: '15px 31px',
        borderTop: '1px solid rgba(0, 0, 0, .05)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        fontWeight: 'bold',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
        '& svg': {
            margin: '0px !important',
        },
    },
    footer: {},
}))

const useDialogStyles = makeStyles(() => ({
    paper: {
        maxWidth: '1000px',
        width: '840px',
        [`@media (max-width:${mobileScreenWidth})`]: {
            margin: '8px ',
            width: 'calc(100% - 16px) !important',
            maxWidth: 'calc(100% - 16px) !important ',
        },
    },
}))

export interface CheckOutAdvancedProps {
    open?: any
    onClose?: any
    onReset?: any
    onCancel?: any
    onSucces?: any
    loading?: boolean
    error?: boolean
    requestPolicy: any
    selectedStartDate: any
    setSelectedStartDate: any
    selectedDuration: any
    setSelectedDuration: any
    justification: any
    setJustification: any
}

export const CheckOutAdvanced: FC<CheckOutAdvancedProps> = (props) => {
    const {
        open,
        onClose,
        onSucces,
        loading,
        requestPolicy,
        selectedStartDate,
        setSelectedStartDate,
        selectedDuration,
        setSelectedDuration,
        justification,
        setJustification,
    } = props
    const { t } = useTranslation()
    const classes = useStyles()
    const dialogClasses = useDialogStyles()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'
    const footerContent = (
        <Box display="flex" width="100%" padding="24px 32px">
            <Box minWidth="167px">
                <EidButton.DrawerActionButton
                    fontColor="#ffffff"
                    bgColor="#307fc1"
                    width="100%"
                    onClick={onSucces}
                    loading={loading}
                >
                    <Box display="flex" alignItems="center">
                        <Icon name="Check" color="#ffffff" />
                    </Box>
                    <Box display="flex" alignItems="center" marginLeft="10px">
                        <Typography style={{ color: '#ffffff' }}>
                            {t('CheckOut')}
                        </Typography>
                    </Box>
                </EidButton.DrawerActionButton>
            </Box>
        </Box>
    )

    const title = t('CheckOutAdvancedMode')

    return (
        <Dialog
            scroll={'body'}
            open={open}
            onClose={onClose}
            aria-labelledby={title}
            aria-describedby={title}
            classes={dialogClasses}
        >
            <Box className={classes.modalHeader}>
                <Typography className={classes.title}>{title}</Typography>

                <Box className={classes.closeIcon}>
                    <IconButton onClick={onClose}>
                        <Icon name="Close" color="#959598" />
                    </IconButton>
                </Box>
            </Box>

            <Box className={classes.sectionHeader}>{t('SetDuration')}</Box>
            <Box className={classes.body}>
                <Box>
                    <ItemDetails.Attribute
                        label={t('DefaultAccessDuration')}
                        labelProps={{ marginRight: '10px' }}
                        value={requestPolicy?.defaultValueInMinutes}
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                    <ItemDetails.Attribute
                        label={t('MaxAccessDuration')}
                        labelProps={{ marginRight: '10px' }}
                        value={requestPolicy?.maximumValueInMinutes}
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                    <ItemDetails.Attribute
                        label={t('SetDuration')}
                        labelProps={{
                            style: {
                                marginRight: '10px',
                                alignSelf: 'center',
                                minWidth: '100px',
                            },
                        }}
                        value={
                            <TimeDurationControl
                                selectedStartDate={selectedStartDate}
                                setSelectedStartDate={setSelectedStartDate}
                                selectedDuration={selectedDuration}
                                setSelectedDuration={setSelectedDuration}
                                maximumDuration={
                                    requestPolicy?.maximumValueInMinutes
                                }
                            />
                        }
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                </Box>
                <Box flex="1">
                    <ItemDetails.Attribute
                        label={t('Justification')}
                        labelProps={{ marginRight: '10px' }}
                        value={
                            <TextInput
                                handleChange={(e: any) =>
                                    setJustification(e.target.value)
                                }
                                value={justification}
                                label={null}
                                placeholder={null}
                            />
                        }
                        orientation={'vertical'}
                        valueProps={{
                            style: { marginBottom: '-10px' },
                            overflowStyleProps,
                        }}
                    />
                </Box>
            </Box>

            <Box className={classes.footer}>{footerContent}</Box>
        </Dialog>
    )
}
