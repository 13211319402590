import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import moment from 'moment'
import {
    useCheckOutCredential,
    useCurrentPerson,
    useAccessRequestPolicy,
} from 'hooks'
import { EidButton } from 'packages/eid-ui'
import { CheckOutAdvanced } from 'components/CheckOutAdvanced'

const CheckOut = ({ data, drawerView }) => {
    const item = data.resource

    const { t } = useTranslation()
    const { data: currentPerson } = useCurrentPerson()

    const [
        checkOutCredential,
        { isLoading: isCheckingOut },
    ] = useCheckOutCredential(item.id)

    const accessRequestPolicy = useAccessRequestPolicy(item.requestPolicyId)

    const [showAdvancedCheckout, setShowAdvancedCheckout] = useState(false)
    const [startDateTime, setStartDateTime] = useState(
        moment(new Date()).utc().format(),
    )
    const [duration, setDuration] = useState('')
    const [justification, setJustification] = useState('')

    useEffect(() => {
        if (accessRequestPolicy) {
            setDuration(accessRequestPolicy.defaultValueInMinutes)
        }
    }, [accessRequestPolicy])

    const handleCheckOut = () => {
        const checkOutData = {
            id: item.id,
            targetPersonId: currentPerson.id,
            startDateTime,
            endDateTime: moment(startDateTime)
                .clone()
                .add(duration, 'm')
                .utc()
                .format(),
            justification,
        }

        checkOutCredential(checkOutData)
        setShowAdvancedCheckout(false)
    }

    const checkOutOptions = [
        {
            onClick: handleCheckOut,
            label: t('Now'),
        },
        {
            onClick: () => {
                setShowAdvancedCheckout(true)
            },
            label: t('AdvancedMode'),
        },
    ]

    const handleChangeStartDate = (startDate) => {
        setStartDateTime(moment(startDate).utc().format())
    }

    const handleChangeDuration = (duration) => {
        setDuration(duration)
    }

    return (
        <>
            {!drawerView ? (
                <Box style={{ height: '24px' }}>
                    <EidButton.CheckOutListing
                        loading={isCheckingOut}
                        disabled={!item.canCheckOut}
                        label={
                            item.canCheckOut ? t('CheckOut') : t('CheckedOut')
                        }
                        onButtonClick={handleCheckOut}
                        checkoutOptions={checkOutOptions}
                    />
                </Box>
            ) : (
                <Box minWidth="170px" height="48px">
                    <EidButton.CheckOutDetailButton
                        loading={isCheckingOut}
                        disabled={!item.canCheckOut}
                        label={
                            item.canCheckOut ? t('CheckOut') : t('CheckedOut')
                        }
                        onButtonClick={handleCheckOut}
                        checkoutOptions={checkOutOptions}
                    />
                </Box>
            )}
            <CheckOutAdvanced
                onSucces={handleCheckOut}
                loading={isCheckingOut}
                open={showAdvancedCheckout}
                onClose={() => setShowAdvancedCheckout(false)}
                requestPolicy={accessRequestPolicy}
                selectedStartDate={moment(startDateTime)}
                setSelectedStartDate={handleChangeStartDate}
                selectedDuration={duration}
                setSelectedDuration={handleChangeDuration}
                justification={justification}
                setJustification={setJustification}
            />
        </>
    )
}

CheckOut.defaultProps = {
    drawerView: false,
}

export default CheckOut
