import { Fragment } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export const RemainingTime = (startTime, endTime) => {
    const { t } = useTranslation()

    const getRemainingTime = (startTime, endTime) => {
        if (!startTime || !endTime) {
            return '-'
        }
        const duration = moment.duration(
            moment.utc(endTime).diff(moment.utc(startTime)),
        )

        const hours = duration.hours().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
        })
        const minutes = duration.minutes().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
        })
        const seconds = duration.seconds().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
        })

        const time = `${hours}:${minutes}:${seconds}`
        const days = duration.days() + ' ' + t('DAYS')

        return duration.days() > 0 ? `${days} ${time}` : time
    }
    return <Fragment>{getRemainingTime(startTime, endTime)}</Fragment>
}
const CredentialRemainingTime = ({ data }) => {
    return (
        <div style={{ textAlign: 'center' }}>
            <RemainingTime
                startTime={data.resource?.currentCheckOutStartTimeUtc}
                endTime={data.resource?.currentCheckOutEndTimeUtc}
            />
        </div>
    )
}

export default CredentialRemainingTime
