import { useQuery } from 'react-query'
import { useAxios } from 'packages/core'

export const useUiActionsByResource = (resourceId, isEnabled = true) => {
    const callApi = useAxios()
    return useQuery(
        ['UI_ACTIONS', resourceId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/uiActions/byResourceId?resourceId=${resourceId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(resourceId) && isEnabled,
        },
    )
}

export const useAddionalProperties = (
    resourceId,
    resourceTypeId,
    locationId,
    fetchData,
) => {
    const callApi = useAxios()
    return useQuery(
        ['ADDITIONAL_PROPERTIES', resourceId, resourceTypeId, locationId],
        () =>
            callApi({
                method: 'GET',
                url: locationId
                    ? `/api/Application/AdditionalProperties/${resourceId}/${resourceTypeId}?locationId=${locationId}`
                    : `/api/Application/AdditionalProperties/${resourceId}/${resourceTypeId}`,
            }).then((data) => data.data),
        {
            enabled:
                Boolean(resourceId) &&
                Boolean(resourceTypeId) &&
                Boolean(fetchData),
        },
    )
}
