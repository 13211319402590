import CheckInButton from './CheckInButton'
import CheckOutButton from './CheckOutButton'

const CredentialStatusButton = ({ data }) => {
    const item = data?.resource

    if (item.canCheckIn) {
        return <CheckInButton data={data} drawerView={false} />
    }
    if (item.canCheckOut) {
        return <CheckOutButton data={data} drawerView={false} />
    }
}

CredentialStatusButton.defaultProps = {
    drawerView: false,
}

export default CredentialStatusButton
